import React from "react";
import TabPanel from "../../../misc/TabPanel";

const TomTabPage = (props) => {
  const { value, index } = props;
  return (
    <TabPanel value={value} index={index}>
      Coming soon
    </TabPanel>
  );
};

export default TomTabPage;
