import React, { useState, useContext } from "react";
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import LabeledValues from "../../../labels/LabeledValues";
import ProductListItem from "../components/ProductListItem";
import OngoingOrderButtonAction from "../components/OngoingOrderButtonAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useOngoingOrderDetailDialog() {
  const [open, setOpen] = useState(false);

  const [order, setorder] = useState({});

  function totalProducts(products) {
    let total = 0;
    for (let index = 0; index < products?.length; index++) {
      total = total + products[index].price * products[index].count;
    }

    return total;
  }

  const showOngoingOrderDetail = (order) => {
    setOpen(true);
    setorder(order);
  };

  const closeOngoingOrderDetail = () => {
    setOpen(false);

  };

  const OngoingOrderDetailDialog = () => {
    let whenDate = new Date(order?.when);
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={closeOngoingOrderDetail}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={closeOngoingOrderDetail}
              aria-label='close'>
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Order details
            </Typography>
            {/* <Button autoFocus color='inherit' onClick={closeOngoingOrderDetail}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <LabeledValues label='Transaction ID' value={order?.transaction_id} />
        <LabeledValues label='Order for' value={whenDate.toUTCString()} />
        <LabeledValues label='Name' value={order?.user?.name} />
        <LabeledValues label='Contact' value={order?.user?.phone} />
        <LabeledValues label='Delivery Address' value={order?.address} />
        <LabeledValues label='Payment' value={order?.paymentType} />
        <LabeledValues label='Notes' value={order?.deliveryNotes} />
        <Typography m={"0.5em"} fontWeight={"bold"} fontSize={20}>
          Products
        </Typography>
        <div>
          {order?.products?.map((product) => {
            return (
              <ProductListItem
                {...product}
              />
            );
          })}
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0.5em",
          }}>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}>
            Total
          </div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}>
            {`P ${order.amount}.0`}
          </div>
        </div>
        <OngoingOrderButtonAction
          order={order}
          additionalActions={closeOngoingOrderDetail}
        />
      </Dialog>
    );
  };

  return [
    showOngoingOrderDetail,
    closeOngoingOrderDetail,
    OngoingOrderDetailDialog,
  ];
}
