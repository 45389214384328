import React, { useState } from "react";
import { ChevronLeft, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { navigate } from "gatsby-link";
import CustomTextField from "../../components/textfield/CustomTextField";
import Agreements from "./agreements";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { signUp } from "../../api/public/auth";
import InfoDialog from "../../components/dialogs/InfoDialog";
import capitalizeFirstLetter from "../../helpers/capitalize";
import "animate.css";
import PhoneTextfield from "../textfield/PhoneTextfield";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = yup.object({
  email: yup.string().email("Invalid email"),
  phone: yup.string().matches(phoneRegExp, "Phone number is not valid"),
  password: yup.string().required("Password is required").min(6),
  confirmpassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const SignUpPage = () => {
  const [dialogState, setDialogState] = useState({
    open: false,
    isSuccess: false,
    title: "",
    message: "",
  });

  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleClose = () => {
    setDialogState((prevState) => ({ ...prevState, open: false }));
  };

  const [selectedPlace, setSelectedPlace] = useState(null);

  const [addressData, setaddressData] = useState({
    address: "",
    long: 0.0,
    lat: 0.0,
  });
  const [showPassword, setShowPassword] = useState(false);

  function handleSearchPlaceSelection(params) {
    //console.log(params)

    //Update place search state
    setSelectedPlace(params);

    //get lat lng
    //console.log(params.label)
    geocodeByAddress(params.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("Successfully got latitude and longitude", { lat, lng });
        //set map local state
        setaddressData((prevState) => ({
          ...prevState,
          address: params.label,
          lat: lat,
          long: lng,
        }));
      });
  }

  function addressNotBlank() {
    if (addressData.address.length > 0) {
      return true;
    }

    return false;
  }

  return (
    <Container>
      <AppBar elevation={0}>
        <Toolbar>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          if (!agreedToTerms) {
            setSubmitting(false);
            return setDialogState((prevState) => ({
              ...prevState,
              open: true,
              isSuccess: false,
              title: "Oops!",
              message:
                "Please agree to the Terms and Conditions, Forward Together Agreement and Privacy Policy",
            }));
          }

          if (addressNotBlank()) {
            setSubmitting(true);
            let userData = {
              name: `${capitalizeFirstLetter(
                data.firstname
              )} ${capitalizeFirstLetter(data.lastname)}`,
              role: 1,
              ...data,
              ...addressData,
            };

            console.log(userData);
            signUp(userData)
              .then((response) => {
                if (response.status === 200) {
                  response.json().then((result) => {
                    console.log(result);
                    setSubmitting(false);
                    setDialogState((prevState) => ({
                      ...prevState,
                      open: true,
                      isSuccess: true,
                      title: "Registration success",
                      message: "Please login again using your credentials",
                    }));
                  });
                } else {
                  response.json().then((result) => {
                    console.log(result);
                    setDialogState((prevState) => ({
                      ...prevState,
                      open: true,
                      isSuccess: false,
                      title: "Registration failed",
                      message: `${result.error}`,
                    }));
                    setSubmitting(false);
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            setSubmitting(false);
            setDialogState((prevState) => ({
              ...prevState,
              open: true,
              isSuccess: false,
              title: "Address is required",
              message: "Please type and select your shop address",
            }));
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ width: "300px", padding: "1em" }}>
                <Typography
                  variant="h2"
                  className="animate__animated animate__fadeIn"
                >
                  Create your account
                </Typography>
              </Box>
              <Paper
                sx={{ width: "300px", padding: "1em" }}
                className="animate__animated animate__fadeIn"
              >
                <Stack spacing={2}>
                  <CustomTextField
                    name="firstname"
                    label="First name"
                    variant="outlined"
                  />
                  <CustomTextField
                    name="lastname"
                    label="Last name"
                    variant="outlined"
                  />
                  <Divider />
                  <Typography>Type and select your address</Typography>
                  <div style={{ zIndex: 1000 }}>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.GATSBY_GOOGLE_KEY}
                      selectProps={{
                        selectedPlace,
                        onChange: handleSearchPlaceSelection,
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            alignItems: "center",

                            margin: 0,
                          }),
                        },
                      }}
                    />
                  </div>
                  <Divider />
                  <CustomTextField
                    name="email"
                    label="Email"
                    variant="outlined"
                    type="email"
                  />
                  {/* <CustomTextField
                    name='phone'
                    label='Phone'
                    variant='outlined'
                    type='number'
                  /> */}
                  <PhoneTextfield
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    type="number"
                  />
                  <Typography>
                    Password needs to be at least 8 characters long with
                    uppercase, lowercase letter and should also contain some
                    numbers
                  </Typography>
                  <CustomTextField
                    name="password"
                    label="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                  <CustomTextField
                    name="confirmpassword"
                    label="Confirm password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />

                  <Agreements
                    agreed={agreedToTerms}
                    toggleAgreed={() => {
                      setAgreedToTerms(!agreedToTerms);
                    }}
                  />

                  <Button
                    variant="contained"
                    type="submit"
                    disable={isSubmitting || !agreedToTerms}
                  >
                    {isSubmitting ? <CircularProgress /> : "Register"}
                  </Button>
                </Stack>
              </Paper>
            </div>
          </Form>
        )}
      </Formik>
      <InfoDialog
        open={dialogState.open}
        handleClose={handleClose}
        title={dialogState.title}
        message={dialogState.message}
        onAccept={() => {
          if (dialogState.isSuccess) navigate(-1);
        }}
      />
    </Container>
  );
};

export default SignUpPage;
