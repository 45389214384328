import React from "react";
import PropTypes from "prop-types";
import { Avatar, IconButton } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

const ProfilePhotoAvatar = ({
  src = "",
  alt = "ProfilePhoto",
  size = "20px",
  onClick = () => {},
}) => {
  return (
    <IconButton onClick={onClick}>
      {src.length > 0 ? (
        <Avatar src={src} alt='profile-photo'>
          {" "}
          {/* <LazyLoadImage
            src={src}
            alt='profile-photo'
            effect={"black-and-white"}
            style={{
              objectFit: "scale-down",
            }}
          /> */}
        </Avatar>
      ) : (
        <Avatar>{alt.substring(0, 2).toUpperCase()}</Avatar>
      )}
    </IconButton>
  );
};

ProfilePhotoAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default ProfilePhotoAvatar;
