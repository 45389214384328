import React, { useState, useEffect } from "react";
import Carousel from "nuka-carousel";
import { AddAPhoto, Delete, ChevronLeft } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { navigate } from "gatsby-link";
import capitalizeFirstLetter from "../../helpers/capitalize";
import TabPanel from "../misc/TabPanel";
import { Box } from "@mui/system";
import { archiveProduct, getProductByID } from "../../api/public/product";
import useConfirmationDialog from "../dialogs/useConfirmationDialog";
import useSparkleSnackBar from "../../custom-hooks/useSparkleSnackbar";
import useEditProductDialog from "./dialogs/useEditProductDialog";
import ApprovalStatus from "./components/ApprovalStatus";
import VisibilityButton from "./components/VisibilityButton";
import PreOrderButton from "./components/PreOrderButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import useAddProductImageDialog from "./dialogs/useAddProductImageDialog";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProductDetails = ({ location }) => {
  const [product, setproduct] = useState(location.state.data);

  const [value, setValue] = useState(0);

  const [showConfirmationDialog, ConfirmationDialog] = useConfirmationDialog();

  const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackBar();

  const [showEditProductDialog, EditProductDialog] = useEditProductDialog();

  const { showAddProductImageDialog, AddProductImageDialog } =
    useAddProductImageDialog();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  useEffect(() => {
    setproduct(showPrimaryImageAsFirstImage(location.state.data))
  }, [])

  function handleArchive() {
    archiveProduct(product)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            showSnackbar("Archived product", "success");
          });
        } else {
          response.json().then((result) => {
            showSnackbar("Something went wrong", "error");
          });
        }
      })
      .catch((e) => console.log(e));
  }

  function refreshValues() {
    getProductByID(product._id)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            showPrimaryImageAsFirstImage(result)
          });
        } else {
          showSnackbar("Something went wrong", "error");
        }
      })
      .catch((e) => console.log(e));
  }


  function showPrimaryImageAsFirstImage(product) {
    let images = product.images
    let primaryImage = product.imagePrimary
    let primaryImageOnArray = images.find(image => image.url === primaryImage)
    let primaryImageIndex = images.findIndex(image => image.url === primaryImage)

    if (primaryImageIndex > -1) {
      let sortedImages = [primaryImageOnArray, ...images.splice(primaryImageIndex)]
      console.log(primaryImage)
      console.log(sortedImages)
      let modifiedProduct = {
        ...product, 
        images: sortedImages
      }
      return modifiedProduct
    }

    return product
  }

  return (
    <div
      style={{
        position: "relative",
      }}>
      {/* Header */}
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "2%",
            left: "2%",
            borderRadius: "100%",
            height: "10px",
            width: "10px",
            zIndex: 1000,
          }}>
          <IconButton
            sx={{
              backgroundColor: "#f1f7fd !important",
              borderRadius: "0.2em !important",
            }}
            onClick={() => {
              navigate(-1);
            }}>
            <ChevronLeft style={{ fill: "#0286FE" }} />
          </IconButton>
        </div>
        <div
          style={{
            position: "absolute",
            top: "2%",
            right: "10%",
            borderRadius: "100%",
            height: "10px",
            width: "10px",
            zIndex: 1000,
          }}>
          <IconButton
            sx={{
              backgroundColor: "#f1f7fd !important",
              borderRadius: "0.2em !important",
            }}
            onClick={() => {
              showAddProductImageDialog(product);
            }}>
            <AddAPhoto style={{ fill: "#0286FE" }} />
          </IconButton>
        </div>

        <Carousel autoplay={true} withoutControls={true} wrapAround={true}>
          {product.images.map((image) => {
            return (
              <LazyLoadImage
                src={image.url + "?auto=compress&w=300"}
                alt={product.name}
                effect='black-and-white'
                width='100%'
                height='400px'
                style={{
                  objectFit: "cover",
                  margin: 0,
                  padding: 0,
                }}
              />
            );
          })}
        </Carousel>
      </div>
      {/* Details */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "0.9em",
          paddingLeft: "0.9em",
          paddingRight: "0.9em",
        }}>
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "1em",
            }}>
            {capitalizeFirstLetter(product.name)}
          </div>
          <div>
            <VisibilityButton
              product={product}
              additionalAction={refreshValues}
            />
          </div>
          <div>
            <ApprovalStatus status={product.isApproved} />{" "}
          </div>
          <div>
            <PreOrderButton
              product={product}
              additionalAction={refreshValues}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginBottom: "1em",
            }}>{`P ${product.price}.0`}</div>
          <div>{`${product.quantity} in stock`}</div>
        </div>
      </div>
      {/* Tab Body */}
      <div
        style={{
          marginBottom: "30px",
        }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='inherit'
          variant='fullWidth'
          aria-label='full width tabs example'>
          <Tab label='Description' {...a11yProps(0)} />
          <Tab label='Ingredients' {...a11yProps(1)} />
          <Tab label='Reviews' {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Box sx={{ p: 3 }}>
            <Typography>{product.description}</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ p: 3 }}>
            <Typography>{product.ingredients}</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ p: 3 }}>
            <Typography>Coming soon!</Typography>
          </Box>
        </TabPanel>
      </div>

      {/* Actions */}
      <div
        style={{
          position: "fixed",

          bottom: "2%",
        }}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Button
              variant='contained'
              onClick={() => {
                showConfirmationDialog({
                  message: "You are about to delete this product",
                  title: "Delete this product?",
                });
              }}>
              <Delete/>
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              variant='contained'
              sx={{ width: "20em" }}
              onClick={() => {
                showEditProductDialog(product);
              }}>
              EDIT
            </Button>
          </Grid>
        </Grid>
      </div>
      <ConfirmationDialog onConfirm={handleArchive} />
      <SparkleSnackBar />
      <EditProductDialog additionalAction={refreshValues} />
      <AddProductImageDialog />
    </div>
  );
};

export default ProductDetails;
