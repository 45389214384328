import { Alert, Collapse } from "@mui/material";
import React, { useState } from "react";

export default function useSparkleAlert() {
  const [alertState, setAlertState] = useState({
    show: false,
    message: " This is an alert — check it out!",
    status: "warning",
  });

  const showSparkleAlert = (
    message = "This is an alert — check it out!",
    status = "warning"
  ) => {
    setAlertState((prevState) => ({
      ...prevState,
      show: true,
      message: message,
      status: status,
    }));
  };

  const closeSparkleAlert = () => {
    setAlertState((prevState) => ({
      ...prevState,
      show: false,
      message: "",
      status: "warning",
    }));
  };
  const SparkleAlert = () => {
    return (
      <Collapse in={alertState.show}>
        <Alert severity={alertState.status}>{alertState.message}</Alert>
      </Collapse>
    );
  };

  return { showSparkleAlert, closeSparkleAlert, SparkleAlert };
}
