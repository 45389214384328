import React from "react";
import ProductCardItem from "../../../cards/ProductCardItem";

const ProductList = ({ products }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}>
      <div
        style={{
          width: "390px",
          display: "flex",
          flexWrap: "wrap",
          paddingTop: "2em",
          paddingBottom: "5em",
        }}>
        {products.map((product) => {
          return <ProductCardItem product={product} />;
        })}
      </div>
    </div>
  );
};

export default ProductList;
