import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CustomTextField from "../../../textfield/CustomTextField";
import { Form, Formik } from "formik";
import { deleteAccount, updateUser } from "../../../../api/public/user";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";
import useSparkleSnackbar from "../../../../custom-hooks/useSparkleSnackbar";
import capitalizeFirstLetter from "../../../../helpers/capitalize";
import { navigate } from "gatsby";
import { useDeleteAccountDialog } from "../components/useDeleteAccountDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function useEditProfile() {
  const [open, setopen] = useState(false);

  const [user, setuser] = useState({});

  const showEditProfileDialog = (userdata) => {
    setopen(true);
    setuser(userdata);
  };

  const closeEditProfileDialog = () => {
    setopen(false);
  };

  const EditProfileDialog = () => {
    const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackbar();

    const { user, cacheUser, removeUserData } = useLoggedUser();

    const { showDeleteAccountDialog, DeleteAccountDialog } =
      useDeleteAccountDialog();

    const handleDeleteAccountOnClick = () => {
      showDeleteAccountDialog();
    };

    const onConfirmDeleteAccount = async () => {
      try {
        await deleteAccount({ userId: user?._id });

        let postMessageData = {
          type: "LOGOUT",
          payload: "",
        };

        if (window.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify(postMessageData)
          );
        }

        removeUserData();

        navigate("/login");

        return;
      } catch (error) {
        throw error;
      }
    };

    return (
      <Dialog
        open={open}
        onClose={closeEditProfileDialog}
        fullScreen
        TransitionComponent={Transition}
      >
        <Formik
          initialValues={{
            firstname: user?.name?.split(" ")[0],
            lastname: user?.name?.split(" ")[1],
            email: user?.email,
            phone: user?.phone,
          }}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            let newUserData = {
              name: `${capitalizeFirstLetter(
                data.firstname
              )} ${capitalizeFirstLetter(data.lastname)}`,
              email: data.email,
              phone: data.phone,
            };

            updateUser(newUserData)
              .then((response) => {
                if (response.status == 200) {
                  response?.json().then((result) => {
                    // console.log(`response from API ${result}`);
                    console.log(result);
                    cacheUser(result);
                    showSnackbar("Update success", "success");
                    setSubmitting(false);
                  });
                } else {
                  response?.json().then((result) => {
                    showSnackbar(`Something went wrong -> ${result}`, "error");
                    setSubmitting(false);
                  });
                }
              })
              .catch((e) => console.log(e));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={closeEditProfileDialog}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant="h6"
                    component="div"
                  >
                    Edit Profile
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Stack spacing={10}>
                  <Stack spacing={2}>
                    <CustomTextField
                      name="firstname"
                      label="First name"
                      variant="outlined"
                    />
                    <CustomTextField
                      name="lastname"
                      label="Last name"
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      autoFocus
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "loading" : "save"}
                    </Button>
                  </Stack>

                  <Stack spacing={2}>
                    <Alert severity="error">
                      <AlertTitle>Warning</AlertTitle>
                      Once you delete your account, there's no getting it back
                      make sure you want to do this.
                    </Alert>

                    <Button
                      variant={"outlined"}
                      fullWidth
                      sx={{ color: "red", borderColor: "red" }}
                      onClick={handleDeleteAccountOnClick}
                    >
                      DELETE ACCOUNT
                    </Button>
                  </Stack>
                </Stack>
              </DialogContent>
            </Form>
          )}
        </Formik>
        <SparkleSnackBar />
        <DeleteAccountDialog
          onConfirm={async () => {
            await onConfirmDeleteAccount();
          }}
        />
      </Dialog>
    );
  };

  return [showEditProfileDialog, closeEditProfileDialog, EditProfileDialog];
}
