import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import useSparkleTimeFormat from "../../../../custom-hooks/useSparkleTimeFormat";
import { updateShop } from "../../../../api/public/shop";
import checkIfArray from "../../../../helpers/checkIfArray";
import useSparkleAlert from "../../../alert/useSparkleAlert";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function useEditShopSchedule() {
  const [open, setOpen] = useState(false);

  const { to24HourFormat, to12HourFormat } = useSparkleTimeFormat();

  const [shopSchedules, setshopSchedules] = useState([]);

  const [tempSchedules, setTempSchedules] = useState([]);

  const { cacheShop } = useLoggedUser();

  const showEditShopSchedule = (schedules) => {
    setOpen(true);

    // console.log(schedules);

    setTempSchedules(schedules);

    let convertedSchedulesTo24 = [];

    for (let index = 0; index < schedules.length; index++) {
      if (
        schedules[index].open.length > 0 &&
        schedules[index].closed.length > 0
      ) {
        convertedSchedulesTo24.push([
          to24HourFormat(schedules[index].open),
          to24HourFormat(schedules[index].closed),
        ]);
      } else {
        convertedSchedulesTo24.push(["", ""]);
      }
    }

    // console.log(convertedSchedulesTo24);

    setshopSchedules(convertedSchedulesTo24);
  };

  const closeEditShopSchedule = () => {
    setOpen(false);
  };

  const EditShopSchedule = ({ onUpdate = () => { } }) => {
    const [isSubmitting, setisSubmitting] = useState(false);

    // const [schedules, onChange] = useState(shopSchedules);

    const [mon, setmon] = useState(shopSchedules[0]);

    const [tue, settue] = useState(shopSchedules[1]);

    const [wed, setwed] = useState(shopSchedules[2]);

    const [thu, setthu] = useState(shopSchedules[3]);

    const [fri, setfri] = useState(shopSchedules[4]);

    const [sat, setsat] = useState(shopSchedules[5]);

    const [sun, setsun] = useState(shopSchedules[6]);

    const { showSparkleAlert, closeSparkleAlert, SparkleAlert } =
      useSparkleAlert();

    const handleRangeTimePicker = (timerange, func, value) => {
      console.log('value changes')
      console.log(value)
      if (timerange === null) {
        console.log('no time range')
        closeSparkleAlert()
        func('') 
        return
      }


      if (timerange) {
        console.log('may time range')
        console.log(timerange)
        let [first, second] = timerange
        
        if(first.length > 0) {
          if(value?.[1]){
            console.log('has value on second')
            //
            if(parseInt(first) <  parseInt(value[1])){
              console.log('committing')
              func([first, value[1]])
            }
            else {
              showSparkleAlert("Time Range Invalid on", "error");
            }
          }
          else {
            console.log('setting range directl')
            func(timerange)
          }
        }



        if(second.length > 0){
          if(value?.[0]){
            if(parseInt(value[0]) < parseInt(second)){
              console.log('commiting')
              func([value[0], second])
            }else {
              showSparkleAlert('Time Range Invalid')
            }
          }
          else {
            console.log('setting range directly')
            func(timerange)
          }
        }
        /*

        let newValue = value ? value : []
        console.log(newValue)

        
        if(first.length > 0) 
          newValue[0] = first
        if(second.length > 0) 
          newValue[1] = second
        
          console.log(newValue)
        
        if (parseInt(newValue[0]) < parseInt(newValue[1])) {
          closeSparkleAlert();
          func(timerange);
        } else {
          showSparkleAlert("Time Range Invalid on", "error");
        } 

        */
      }
    };

    const comparePrevAndNewSched = (prevSchedArray, newSchedArray) => {
      let similarities = [];
      for (let index = 0; index < prevSchedArray.length; index++) {
        if (
          prevSchedArray[index].open === newSchedArray[index].open &&
          prevSchedArray[index].closed === newSchedArray[index].closed
        ) {
          similarities.push(true);
        } else {
          similarities.push(false);
        }
      }

      return similarities.every((v) => v === true);
    };

    return (
      <Dialog
        open={open}
        onClose={closeEditShopSchedule}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogContent style={{ padding: "2em important!" }}>
          <Stack spacing={2}>
            <Typography variant="h6">Input your shop schedule's</Typography>
            <div>
              <Typography>Monday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  console.log('thi sis event')
                  console.log(mon)
                  console.log(event)
                  handleRangeTimePicker(event, setmon) 
                }}
                value={mon}
              />
            </div>
            <div>
              <Typography>Tuesday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, settue);
                }}
                required
                value={tue}
              />
            </div>
            <div>
              <Typography>Wednesday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, setwed);
                }}
                required
                value={wed}
              />
            </div>
            <div>
              <Typography>Thursday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, setthu);
                }}
                required
                value={thu}
              />
            </div>
            <div>
              <Typography>Friday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, setfri);
                }}
                required
                value={fri}
              />
            </div>
            <div>
              <Typography>Saturday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, setsat);
                }}
                required
                value={sat}
              />
            </div>
            <div>
              <Typography>Sunday</Typography>
              <TimeRangePicker
                onChange={(event) => {
                  handleRangeTimePicker(event, setsun);
                }}
                required
                value={sun}
              />
            </div>
            <SparkleAlert />

            <Button
              variant="contained"
              disabled={isSubmitting}
              onClick={() => {
                // setisSubmitting(true);
                let updatedSchedulesTo12Hour = {
                  schedule: [
                    {
                      open: to12HourFormat(checkIfArray(mon)[0]),
                      closed: to12HourFormat(checkIfArray(mon)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(tue)[0]),
                      closed: to12HourFormat(checkIfArray(tue)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(wed)[0]),
                      closed: to12HourFormat(checkIfArray(wed)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(thu)[0]),
                      closed: to12HourFormat(checkIfArray(thu)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(fri)[0]),
                      closed: to12HourFormat(checkIfArray(fri)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(sat)[0]),
                      closed: to12HourFormat(checkIfArray(sat)[1]),
                    },
                    {
                      open: to12HourFormat(checkIfArray(sun)[0]),
                      closed: to12HourFormat(checkIfArray(sun)[1]),
                    },
                  ],
                };

                let sameSched = comparePrevAndNewSched(
                  tempSchedules,
                  updatedSchedulesTo12Hour.schedule
                );


                console.log('updated schedules would be ')
                console.log(updatedSchedulesTo12Hour.schedule)

                if (sameSched) {
                  showSparkleAlert(
                    "Schedule unchanged, make sure to fill all fields hour and minutes on the time range",
                    "warning"
                  );
                  return;
                }

                updateShop(updatedSchedulesTo12Hour)
                  .then((response) => {
                    if (response.status === 200) {
                      response.json().then((result) => {
                        cacheShop(result);
                        setisSubmitting(false);
                        closeEditShopSchedule();
                        // showSparkleAlert("Update complete!", "success");
                        onUpdate();
                      });
                    } else {
                      response.json().then((result) => {
                        setisSubmitting(false);
                        showSparkleAlert("Something went wrong", "error");
                      });
                    }
                  })
                  .catch((e) => console.log(e));
              }}
            >
              {isSubmitting ? "Loading" : "Update"}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };

  return { showEditShopSchedule, closeEditShopSchedule, EditShopSchedule };
}
