import SecureLS from "secure-ls";
import { API } from "../../api-config";

export const getUser = async (userId) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");

  return await fetch(`${API}/user/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const getUserFromExternalToken = async (userId, token) => {
  return await fetch(`${API}/user/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateUser = async (newUserData) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/user/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newUserData),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateUserPhoto = async (newProfilePhoto) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  const formdata = new FormData();

  formdata.append("file", newProfilePhoto);

  return await fetch(`${API}/user/photo/${user._id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const deleteAccount = async ({ userId }) => {
  try {
    const ls = new SecureLS({ encodingType: "aes" });

    const token = ls.get("token");
    const user = ls.get("user");

    let response = await fetch(`${API}/user/delete/${user._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: "account deletion" }),
    });

    if (response?.status !== 200) {
      throw Error("Something went wrong");
    }

    return response.json();
  } catch (error) {
    throw error;
  }
};
