import { Check, Remove } from "@mui/icons-material";
import { Chip, Popover, Typography } from "@mui/material";
import React from "react";

const ApprovalStatus = ({ status }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Chip
        sx={{
          marginTop: "0.2em",
        }}
        icon={status ? <Check /> : <Remove />}
        color={status ? "success" : "error"}
        onClick={handleClick}
        label={status ? "Approved" : "Waiting for approval"}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Typography sx={{ p: 2 }}>
          {status
            ? "This product is approved you can sell this product now"
            : "Please wait for us to approve this product."}
        </Typography>
      </Popover>
    </>
  );
};

export default ApprovalStatus;
