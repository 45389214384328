import React, { useState, useRef, useEffect } from "react";
import { Close, HighlightOff } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Box,
  Slide,
  Stack,
  Toolbar,
  Typography,
  DialogContent,
  Snackbar,
  Alert,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { updateShop } from "../../../../api/public/shop";
import ListItem from "../../../cards/ListItem";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CustomTextField from "../../../textfield/CustomTextField";
import CustomMultiLineTextField from "../../../textfield/CustomMultiLineTextField";
import { addProduct } from "../../../../api/public/product";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";
import useSparkleSnackbar from "../../../../custom-hooks/useSparkleSnackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const validationSchema = yup.object({
  // name: yup.string().required(),
  // address: yup.string().required(),
  // preparation: yup.string().max(300, "Preparation is too long").required(),
  // inspiration: yup.string().max(300, "Inspiration is too long").required(),
  // status: yup.boolean().required(),
});

const EditShopDialog = ({ open, data, handleClose = () => {} }) => {
  const [shopStatus, setShopStatus] = React.useState(data.status);

  const { userData, cacheShop, cacheUserData } = useLoggedUser();

  const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackbar();

  const handleSwitchChange = (event) => {
    setShopStatus(event.target.checked);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <Formik
        initialValues={{
          name: data.name,
          address: data.address,
          inspiration: data.inspiration,
          preparation: data.preparation,
          status: data.status,
          shopId: data._id,
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          let shopData = {
            ...data,
            status: shopStatus,
          };

          // setSubmitting(false);
          updateShop(shopData).then((response) => {
            if (response.status === 200) {
              response.json().then((result) => {
                setSubmitting(false);
                //console.log(result);
                cacheShop(result);
                showSnackbar("Shop updated.", "success");
              });
            } else {
              response.json().then((result) => {
                setSubmitting(false);
                showSnackbar(result.error, "error");
                console.log(result);
              });
            }
          });
        }}>
        {({ isSubmitting }) => (
          <Form>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleClose}
                  aria-label='close'>
                  <Close />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant='h6'
                  component='div'>
                  Update Shop
                </Typography>
                <Button
                  autoFocus
                  color='inherit'
                  type='submit'
                  disabled={isSubmitting}>
                  {isSubmitting ? "loading" : "save"}
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <Stack spacing={2}>
                <CustomTextField
                  name='name'
                  label='Shop name'
                  variant='outlined'
                  // defaultValue={data.name}
                />
                <CustomMultiLineTextField
                  name='inspiration'
                  label='Inspiration'
                  variant='outlined'
                  multiline
                  rows={3}
                />
                <CustomMultiLineTextField
                  name='preparation'
                  label='Preparation'
                  variant='outlined'
                  multiline
                  rows={3}
                />
                <div>
                  <FormControlLabel
                    value='status'
                    control={
                      <>
                        {" "}
                        <strong style={{ color: shopStatus ? "green" : "red" }}>
                          {" "}
                          {shopStatus ? "OPEN" : "CLOSED"}{" "}
                        </strong>{" "}
                        <Switch
                          color='primary'
                          checked={shopStatus}
                          onChange={handleSwitchChange}
                        />
                      </>
                    }
                    label='Status: '
                    labelPlacement='start'
                  />
                </div>
                <Divider />
              </Stack>
            </DialogContent>
          </Form>
        )}
      </Formik>
      <SparkleSnackBar />
    </Dialog>
  );
};

export default EditShopDialog;
