import React, { useContext } from "react";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Container,
  CssBaseline,
  Paper,
  Toolbar,
} from "@mui/material";
import {
  Dashboard,
  Menu,
  Person,
  Refresh,
  Settings,
} from "@mui/icons-material";

import OrderPage from "./OrderPage/OrderPage";
import HistoryPage from "./HistoryPage/HistoryPage";
import MenuPage from "./MenuPage/MenuPage";
import ShopPage from "./ShopPage/ShopPage";
import SettingsPage from "./SettingsPage/SettingsPage";
import {
  HomePageCurrentPageIndex,
  OrderBadgeState,
} from "../../context/HomePageContext/HomePageContext";
import "animate.css";
import ThemeContext, {
  SelectedTheme,
} from "../../context/ThemeContext/ThemeContext";

import BottomNav from "./BottomNav/BottomNav";

// import useLoggedUser from "../../custom-hooks/useLoggedUser";
// import { navigate } from "gatsby-link";

const HomePage = () => {
  const [currentSelectedPageIndex, setCurrentPageIndex] = useContext(
    HomePageCurrentPageIndex
  );

  const handleChange = (event, newValue) => {
    setCurrentPageIndex(newValue);
  };

  function pages(page) {
    switch (page) {
      case "orders":
        return <OrderPage />;
      case "history":
        return <HistoryPage />;
      case "menu":
        return <MenuPage />;
      case "shop":
        return <ShopPage />;
      case "settings":
        return <SettingsPage />;

      default:
        return <OrderPage />;
    }
  }

  return (
    <ThemeContext>
      <CssBaseline />
      <Container sx={{ padding: 0 }}>
        {pages(currentSelectedPageIndex)}
        <Toolbar />
        <BottomNav
          onChange={handleChange}
          currentSelectedPageIndex={currentSelectedPageIndex}
        />
      </Container>
    </ThemeContext>
  );
};

export default HomePage;
