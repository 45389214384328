import React, { useState, useEffect, useContext } from "react";
import { Add } from "@mui/icons-material";
import { CssBaseline, Fab } from "@mui/material";
import AddProductDialog from "./components/AddProductDialog";
import { getShopProducts } from "../../../api/public/product";
import useLoggedUser from "../../../custom-hooks/useLoggedUser";
import ProductList from "./components/ProductList";
import TopBar from "../TopBar/TopBar";
import CenteredProgress from "../../progress/centeredProgress";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { ThemeProvider } from "@emotion/react";

const fabStyle = {
  position: "fixed",
  bottom: "5em",
  right: 16,
  zIndex: "999",
};

const MenuPage = () => {
  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);

  const { userShop } = useLoggedUser();

  const [addProductDialogState, setAddProductDialogState] = useState({
    open: false,
  });

  const [products, setproducts] = useState(null);

  const handleClose = () => {
    setAddProductDialogState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  useEffect(() => {
    getShopProducts(userShop._id).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          console.log(result);
          setproducts(result);
        });
      } else {
        response.json().then((result) => {
          alert(result.error);
        });
      }
    });
  }, [addProductDialogState]);

  if (!products) {
    return (
      <ThemeProvider theme={themeValues}>
        <CenteredProgress />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={themeValues}>
      <CssBaseline />
      <div>
        <TopBar title='Menu' subtitle='Manage and review your products' />
        <div>
          <ProductList products={products} />
        </div>
        <Fab
          sx={fabStyle}
          variant='extended'
          color='primary'
          aria-label='add'
          onClick={() => {
            setAddProductDialogState((prevState) => ({
              ...prevState,
              open: true,
            }));
          }}>
          <Add sx={{ mr: 1 }} />
          Add Product
        </Fab>
        <AddProductDialog
          open={addProductDialogState.open}
          handleClose={handleClose}
        />
      </div>
    </ThemeProvider>
  );
};

export default MenuPage;
