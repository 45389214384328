import { Check, Close } from "@mui/icons-material";
import { Chip, CircularProgress, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  updatePreorder,
  updateProductStatus,
} from "../../../api/public/product";

const PreOrderButton = ({ product, additionalAction }) => {
  const [isLoading, setisLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const iconHandler = (status) => {
    switch (status) {
      case true:
        return <Check />;
      case false:
        return <Close />;
      default:
        return <CircularProgress size={12} />;
    }
  };

  const labelHandler = (status) => {
    switch (status) {
      case true:
        return "Pre-Order";
      case false:
        return "Set to pre-order";
      default:
        return "Loading";
    }
  };

  const colorHandler = (status) => {
    switch (status) {
      case true:
        return "success";
      case false:
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <div>
      <Chip
        sx={{
          marginTop: "0.2em",
        }}
        icon={
          isLoading ? (
            <CircularProgress size={12} />
          ) : (
            iconHandler(product?.isPreOrder)
          )
        }
        color={isLoading ? "warning" : colorHandler(product?.isPreOrder)}
        label={isLoading ? "Loading" : labelHandler(product?.isPreOrder)}
        onClick={(e) => {
          // if (product.isPreOrder) {
          setisLoading(true);
          updatePreorder(product, !product?.isPreOrder)
            .then((response) => {
              if (response.status === 200) {
                additionalAction();
                setisLoading(false);
              } else {
                additionalAction();
                setisLoading(false);
              }
            })
            .catch((e) => console.log(e));
          // } else {
          //   handleClick(e);
          // }
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Typography sx={{ p: 2 }}>
          Please wait for approval before you set this to pre-order
        </Typography>
      </Popover>
    </div>
  );
};

export default PreOrderButton;
