import { API } from "../../api-config";
import SecureLS from "secure-ls";

export const getShopProducts = async (shopId) => {
  return await fetch(`${API}/product/list/shop/${shopId}?ismerchant=true`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const getProductByID = async (productId) => {
  return await fetch(`${API}/product/${productId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const addProduct = async (productData, shopId) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");

  const formdata = new FormData();

  // console.log(productData.name);

  for (let index = 0; index < productData.images.length; index++) {
    formdata.append("file", productData.images[index]);
  }
  formdata.append("isForPets", productData.isForPets);
  formdata.append("category", productData.category);
  formdata.append("name", productData.name);
  formdata.append("quantity", productData.quantity);
  formdata.append("price", productData.price);
  formdata.append("description", productData.description);
  formdata.append("ingredients", productData.ingredients);
  formdata.append("sizes", JSON.stringify(productData.sizes));
  formdata.append("addons", JSON.stringify(productData.addons));
  console.log(...formdata);

  return await fetch(`${API}/product/${shopId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateProduct = async (product, productNewData) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/product/${product._id}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(productNewData),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateProductStatus = async (product, status) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/product/${product._id}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: status }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updatePreorder = async (product, status) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/product/${product._id}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ isPreOrder: status }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const archiveProduct = async (product) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/product/${product._id}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ isArchived: true }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const addAdditionalProductPictures = async (images, productId) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");

  const formdata = new FormData();

  for (let index = 0; index < images.length; index++) {
    formdata.append("file", images[index]);
  }

  return await fetch(`${API}/product/imageBrowser/${productId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const generateUploadUrl = async (_file) => {
  return await fetch(
    "https://q9ffufgi6h.execute-api.ap-southeast-1.amazonaws.com/dev/internal/sparkle/food/v1/upload",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: `{"file_type":"${_file}"}`,
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadImage = async (uploadURL, blobData) => {
  return await fetch(uploadURL.uploadURL, {
    method: "PUT",
    body: blobData,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};
