import { CameraAlt } from "@mui/icons-material";
import { CssBaseline, IconButton } from "@mui/material";
import React from "react";
import NoImage from "../../../../assets/images/no-image.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import { ThemeProvider } from "@emotion/react";

const ShopBanner = ({ shopData, onClick = () => {}, theme }) => {
  return (
    <CssBaseline>
      <div
        style={{
          position: "relative",
        }}>
        <div
          style={{
            position: "absolute",
            top: "3%",
            right: "2%",
            zIndex: "999",
          }}>
          <IconButton
            sx={{
              backgroundColor: `${theme.palette.primary.main} !important`,
              borderRadius: "0.2em !important",
            }}
            onClick={onClick}>
            <CameraAlt sx={{ color: "white" }} />
          </IconButton>
        </div>

        {shopData.banner ? (
          <LazyLoadImage
            src={shopData.banner}
            width='100%'
            height='300px'
            effect={"black-and-white"}
            style={{
              objectFit: "cover",
              backgroundColor: "#F2F2F2",
              zIndex: 0,
            }}
          />
        ) : (
          <LazyLoadImage
            src={NoImage}
            width='100%'
            height='300px'
            style={{
              objectFit: "scale-down",
              backgroundColor: "#F2F2F2",
              zIndex: 0,
            }}
            effect={"black-and-white"}
          />
        )}
      </div>
    </CssBaseline>
  );
};

export default ShopBanner;
