import React, { useState, useRef } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { uploadShopLogo } from "../../../../api/public/shop";
import { Close } from "@mui/icons-material";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";
import NoImage from "../../../../assets/images/no-image.png";
import useSparkleSnackbar from "../../../../custom-hooks/useSparkleSnackbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useEditLogo() {
  const [open, setopen] = useState(false);

  const { userShop } = useLoggedUser();

  const showEditLogo = () => {
    setopen(true);
  };

  const closeEditLogo = () => {
    setopen(false);
  };

  const EditLogo = () => {
    const inputFile = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedImage, setSelectedImage] = useState(
      userShop.logo ? userShop.logo : NoImage
    );

    const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackbar();

    const [selectedImageFile, setSelectedImageFile] = useState({});

    const fileSelectedHandler = (event) => {
      //console.log(event.target.files[0]);
      if (event.target.files[0]) {
        setSelectedImage(URL.createObjectURL(event.target.files[0]));
        setSelectedImageFile(event.target.files[0]);
      }
    };

    function hexToDecimal(hex) {
      return parseInt(hex.replace("#", ""), 16)
    }

    const request = new XMLHttpRequest();
    request.open("POST", "https://discord.com/api/webhooks/986892500465778698/sng4J61H289m-_fmha1toZ6JReO2dHxxfaBmH8g5RxY6VbPwL9yRFmT9acy0SWCB9Ym6");
    request.setRequestHeader('Content-type', 'application/json');

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={closeEditLogo}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={closeEditLogo}
              aria-label='close'>
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Edit Shop Logo
            </Typography>
            <Button
              color='inherit'
              disabled={isSubmitting}
              onClick={() => {
                setIsSubmitting(true);
                uploadShopLogo(selectedImageFile)
                  .then((response) => {
                    if (response.status === 200) {
                      response?.json().then((result) => {

                        let myEmbed = {
                          author: {
                            name: result.name,
                            icon_url: result.logo
                          },
                          //  title: "Merchant Update",
                          description: `Uploaded a new logo. \n${result.logo}`,
                          image: {
                            url: result.logo
                          },
                          color: hexToDecimal("#1976d2"),
                          timestamp: new Date()
                        }

                        const params = {
                          username: "Captain Sparkle",
                          avatar_url: "https://cdn.discordapp.com/avatars/823325081354502155/73db5c72076c231c27449c03c1dc2940.webp",
                          embeds: [myEmbed]
                        }

                        request.send(JSON.stringify(params));

                        setIsSubmitting(false);
                        showSnackbar(
                          "Upload success please refresh to see your changes",
                          "success"
                        );
                      });
                    } else {
                      response?.json().then((result) => {
                        console.log(result);
                        setIsSubmitting(false);
                        showSnackbar(result?.error, "error");
                      });
                    }
                  })
                  .catch((e) => console.log(e));
              }}>
              {isSubmitting ? "Loading" : "save"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Stack spacing={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <LazyLoadImage
                src={selectedImage}
                alt='shop-logo'
                effect='black-and-white'
                style={{
                  height: "300px",
                  width: "300px",
                  borderRadius: "50%",
                  backgroundColor: "transparent",
                  padding: ".2rem",
                }}
              />
            </div>
            <input
              type='file'
              id='file'
              ref={inputFile}
              onChange={fileSelectedHandler}
              style={{ display: "none" }}
            />
            <Button
              variant='contained'
              onClick={() => {
                inputFile.current.click();
              }}>
              Upload Photo
            </Button>
          </Stack>
        </DialogContent>
        <SparkleSnackBar />
      </Dialog>
    );
  };

  return { showEditLogo, closeEditLogo, EditLogo };
}
