import React from 'react'
import Iframe from 'react-iframe'
import FullScreenDialog from '../../dialogs/fullScreenDialog'

const TermsConditionsDialog = ({open, onClose =  () => {}}) => {
	return <FullScreenDialog open = {open} onClose = {onClose}>
		<Iframe width='100%' height='100%' url='https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Terms%20of%20Use.pdf?alt=media&token=5ce66bb5-a4d9-4e7b-99cb-7413deeb81b5' />
	</FullScreenDialog>
}

export default TermsConditionsDialog