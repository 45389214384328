export default function useSparkleTimeFormat() {
  //This function will only accept this type of format "8:00 am" to a 24 hour format
  const to24HourFormat = (time12h) => {
    if (time12h.length <= 0) {
      return "";
    }

    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  //This function will convert a 24 hour format "8:00" to this 12 hour format "8:00 am"
  const to12HourFormat = (time24h) => {
    if (time24h.length <= 0) {
      return "";
    }

    let [hour, minutes] = time24h.split(":");

    let modifier = hour < 12 ? "am" : "pm";

    hour = hour % 12 || 12;

    return `${hour}:${minutes} ${modifier}`;
  };

  return { to24HourFormat, to12HourFormat };
}
