import React, { useState, useEffect } from "react";
import { createTheme } from "@mui/material/styles";
import { green, blue } from "@mui/material/colors";
import SecureLS from "secure-ls";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: blue[700],
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#14385C",
    },
    background: {
      default: "#202124",
    },
  },
});

export const SelectedTheme = React.createContext(lightTheme);

const ThemeContext = ({ children }) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const getCurrentTheme = () => {
    //Get current theme
    let themeMode = ls.get("themeMode");

    //Set the theme name to default if null
    return themeMode ? themeMode : "light";
  };

  const [themeName, setThemeName] = useState(getCurrentTheme());

  const [themeValues, changeTheme] = useState(lightTheme);

  const init = () => {
    //Get current theme
    let themeMode = ls.get("themeMode");

    console.log(themeMode);

    //If there is no theme selected set to default
    if (!themeMode) {
      return;
    } else {
      switch (themeMode) {
        case "light":
          setThemeName("light");
          changeTheme(lightTheme);
          break;
        case "dark":
          setThemeName("dark");
          changeTheme(darkTheme);
          break;
        default:
          changeTheme(lightTheme);
          break;
      }
    }
  };

  const toggleDarkTheme = () => {
    //Get current theme
    let themeMode = ls.get("themeMode");

    //If there is no theme selected set to dark since light is the default

    switch (themeMode) {
      case "dark":
        ls.set("themeMode", "light");
        changeTheme(lightTheme);
        setThemeName("light");
        break;
      case "light":
        ls.set("themeMode", "dark");
        changeTheme(darkTheme);
        setThemeName("dark");
        break;
      default:
        ls.set("themeMode", "dark");
        changeTheme(darkTheme);
        setThemeName("dark");
        break;
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <SelectedTheme.Provider value={[themeName, themeValues, toggleDarkTheme]}>
      {children}
    </SelectedTheme.Provider>
  );
};

export default ThemeContext;
