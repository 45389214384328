function shareUrl(name, url){
	if(navigator.share){
		navigator.share({
			title: name,
			url: url
		}).then(() => {
			console.log('thanks for sharing')
		}).catch((err) => {
			alert('Error while sharing')
			console.log(err)
		})
	}else{
		alert('Browser does not support this api.')
	}
}

export default shareUrl