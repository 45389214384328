import { Check, Close } from "@mui/icons-material";
import { Chip, CircularProgress, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { updateProductStatus } from "../../../api/public/product";

const VisibilityButton = ({ product, additionalAction }) => {
  const [isLoading, setisLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const iconHandler = (status) => {
    switch (status) {
      case true:
        return <Check />;
      case false:
        return <Close />;
      default:
        return <CircularProgress size={12} />;
    }
  };

  const labelHandler = (status) => {
    switch (status) {
      case true:
        return "Available";
      case false:
        return "Not available";
      default:
        return "Loading";
    }
  };

  const colorHandler = (status) => {
    switch (status) {
      case true:
        return "success";
      case false:
        return "error";
      default:
        return "warning";
    }
  };

  return (
    <div>
      <Chip
        icon={
          isLoading ? (
            <CircularProgress size={12} />
          ) : (
            iconHandler(product?.status)
          )
        }
        color={isLoading ? "warning" : colorHandler(product?.status)}
        label={isLoading ? "Loading" : labelHandler(product?.status)}
        onClick={(e) => {
          if (product.isApproved) {
            setisLoading(true);
            updateProductStatus(product, !product?.status)
              .then((response) => {
                if (response.status === 200) {
                  additionalAction();
                  setisLoading(false);
                } else {
                  additionalAction();
                  setisLoading(false);
                }
              })
              .catch((e) => console.log(e));
          } else {
            handleClick(e);
          }
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}>
        <Typography sx={{ p: 2 }}>
          Please wait for approval before you set this to available
        </Typography>
      </Popover>
    </div>
  );
};

export default VisibilityButton;
