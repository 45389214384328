import React, { useState, useEffect } from "react";

import { getAllShopOrders } from "../../api/public/order";

import socket from "../../api/socket";

export const TriggerOrderRefresh = React.createContext(false);

export const OrdersLoading = React.createContext(false);

export const OrdersTodayState = React.createContext([]);

export const UserShop = React.createContext(null);

const OrderPageContext = ({ children }) => {
  const [ordersToday, setordersToday] = useState([]);

  const [refresh, setrefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [userShop, setUserShop] = useState(null);

  const getShopOrderHandler = () => {
    setIsLoading(true);
    getAllShopOrders()
      .then((response) => {
        if (response.status === 200) {
          response.json().then((orders) => {
            setordersToday(orders);
            setIsLoading(false);
          });
        } else {
          response.json().then((result) => {
            setordersToday(result);
            setIsLoading(false);
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (userShop) {
      socket.on(`new-order-${userShop._id}`, (data) => {
        setordersToday((prevState) => [data, ...prevState]);
      });
    }
  }, [userShop]);

  useEffect(() => {
    getShopOrderHandler();
  }, [refresh]);

  return (
    <OrdersTodayState.Provider value={[ordersToday, setordersToday]}>
      <TriggerOrderRefresh.Provider value={[refresh, setrefresh]}>
        <OrdersLoading.Provider value={[isLoading, setIsLoading]}>
          <UserShop.Provider value={[userShop, setUserShop]}>
            {children}
          </UserShop.Provider>
        </OrdersLoading.Provider>
      </TriggerOrderRefresh.Provider>
    </OrdersTodayState.Provider>
  );
};

export default OrderPageContext;
