import React, { useEffect, useState, useContext } from "react";
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  Fab,
  CssBaseline,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Box } from "@mui/system";
import { CalendarToday, Edit } from "@mui/icons-material";
import useLoggedUser from "../../../custom-hooks/useLoggedUser";
import { getShop } from "../../../api/public/shop";
import EditShopDialog from "./components/EditShopDialog";
import useEditLogo from "./components/useEditLogo";
import useEditBanner from "./components/useEditBanner";
import Grid from "@mui/material/Grid";
import "../../../assets/css/style.css";
import ShopLogoAvatar from "./components/ShopLogoAvatar";
import ShopBanner from "./components/ShopBanner";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import useEditShopLocation from "./components/useEditShopLocation";
import useEditShopSchedule from "./components/useEditShopSchedule";
import CenteredProgress from "../../progress/centeredProgress";
import "animate.css";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { ThemeProvider } from "@emotion/react";
import { ShopData } from "../../../context/ShopPageContext/ShopPageContext";
import ShareUrl from "./ShareUrl";

const fabStyle = {
  position: "fixed",
  bottom: "5em",
  right: 16,
};

// const SchedulefabStyle = {
//   position: "fixed",
//   bottom: "5em",
//   right: 150,
// };

// const LocationfabStyle = {
//   position: "fixed",
//   bottom: "5em",
//   right: 210,
// };

const ShopPage = () => {
  const { userShop } = useLoggedUser();

  const [shopData, setShopData] = useContext(ShopData);

  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);

  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [editDialogState, setEditDialogState] = useState({
    open: false,
  });

  const { showEditBanner, EditBanner } = useEditBanner();

  const { showEditLogo, EditLogo } = useEditLogo();

  const { showEditShopLocation, EditShopLocation } = useEditShopLocation();

  const { showEditShopSchedule, EditShopSchedule } = useEditShopSchedule();

  const handleClose = () => {
    setEditDialogState((prevState) => ({
      ...prevState,
      open: false,
    }));
    getShop(userShop._id).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          console.log(result);
          setShopData(result);
        });
      } else {
        response.json().then((result) => {
          alert(result.error);
        });
      }
    });
  };

  useEffect(() => {
    // console.log({userData.shop._id})
    getShop(userShop._id).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          setShopData(result);
        });
      } else {
        response.json().then((result) => {
          alert(result.error);
        });
      }
    });
  }, []);

  const actions = [
    { icon: <LocationOnIcon />, name: "Edit Location" },
    { icon: <CalendarToday />, name: "Edit Schedule" },
    { icon: <AssignmentIcon />, name: "Edit Details" },
  ];

  const handleSpeedDialClick = (action) => {
    switch (action) {
      case "Edit Location":
        showEditShopLocation();
        break;
      case "Edit Schedule":
        showEditShopSchedule(shopData.schedule);
        break;
      case "Edit Details":
        setEditDialogState((prevState) => ({
          ...prevState,
          open: true,
        }));
        break;
      default:
        const none = () => {};
        break;
    }
  };

  if (!shopData) {
    return (
      <ThemeProvider theme={themeValues}>
        <CenteredProgress />
      </ThemeProvider>
    );
  }

  const onShare = () => {
    let id = `${
      shopData.name
        .toLowerCase()
        .replace(/[^\w\s]/gi, "")
        .trim()
        .split(" ")[0]
    }--${shopData._id}`;
    let sparkleUrl = "https://app.sparkles.com.ph";
    let url = `${sparkleUrl}/shop/${id}`;
    console.log(url);
    ShareUrl(shopData.name, url);
  };

  return (
    <ThemeProvider theme={themeValues}>
      <CssBaseline />
      <Container
        sx={{ padding: 0 }}
        className="animate__animated animate__fadeIn"
      >
        <ShopBanner
          shopData={shopData}
          onClick={showEditBanner}
          theme={themeValues}
        />
        <Box
          sx={{
            backgroundColor: themeValues.palette.primary,
            position: "relative",
            top: "-15px",
            // borderTopLeftRadius: "18px",
            // borderTopRightRadius: "18px",
            // boxShadow: "0px 0px 10px rgb(153, 151, 151)",
            minHeight: "43.2em",
            width: "100%",
            zIndex: 999,
          }}
        >
          <ShopLogoAvatar
            shopData={shopData}
            onClick={showEditLogo}
            theme={themeValues}
          />
          <Grid container spacing={1}>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Shop ID:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box pl={1}>{shopData._id}</Box>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Shop Name:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box pl={1}>{shopData.name}</Box>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Address:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box pl={1}>{shopData.address}</Box>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Status:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box
                  pl={1}
                  style={{
                    color: shopData.status ? "green" : "red",
                  }}
                >
                  {shopData.status ? "OPEN" : "CLOSED"}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Business Hours:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                {shopData.schedule.map(function (val, index) {
                  return (
                    <div
                      style={{
                        fontSize: "12px",
                      }}
                    >{`${daysOfTheWeek[index]} ${val.open} - ${val.closed} `}</div>
                  );
                })}
              </Typography>
            </Grid>
            <Box
              component="p"
              sx={{ display: "block" }}
              style={{ textAlign: "center" }}
            >
              Set your store to [OPEN] so buyers can see your shop.
            </Box>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Preparation:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box pl={1}>{shopData.preparation}</Box>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography variant="subtitle2">
                <Box fontWeight="fontWeightBold" pl={1}>
                  Inspiration:
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography variant="subtitle2">
                <Box pl={1}>{shopData.inspiration}</Box>
              </Typography>
            </Grid>
          </Grid>
          <div style={{ textAlign: "center", paddingTop: "1em" }}>
            <Typography variant="subtitle2">
              <Box fontWeight="fontWeightBold" pl={1}>
                <Button variant="contained" onClick={onShare}>
                  Share Shop URL
                </Button>
              </Box>
            </Typography>
          </div>
        </Box>
        <SpeedDial
          ariaLabel="SpeedDial Edit Shop"
          sx={fabStyle}
          icon={<Edit />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                handleSpeedDialClick(action.name);
              }}
            />
          ))}
        </SpeedDial>
        <EditShopDialog
          open={editDialogState.open}
          data={shopData}
          handleClose={handleClose}
        />
        <EditBanner />
        <EditLogo />
        <EditShopLocation />
        <EditShopSchedule onUpdate={handleClose} />
      </Container>
    </ThemeProvider>
  );
};

export default ShopPage;
