import React from "react";
import { Paper } from "@mui/material";
import capitalizeFirstLetter from "../../helpers/capitalize";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "animate.css";

const ProductCardItem = ({ product }) => {
  return (
    <Paper
      className='animate__animated animate__fadeInDown'
      sx={{
        position: "relative",
        width: "175px",
        margin: "9px",
        maxWidth: "60%",
        transition: "0.3s",
      }}>
      <div
        style={{
          position: "absolute",
          top: "2%",
          right: "2%",
          borderRadius: "100%",
          height: "10px",
          width: "10px",
          backgroundColor: product.isApproved ? "green" : "red",
          zIndex: "1",
        }}></div>
      <Link to={`/product/details`} state={{ data: product }}>
        <LazyLoadImage
          src={product.images[0].url}
          alt='product-image'
          width='100%'
          height='160px'
          effect={"black-and-white"}
          style={{
            borderTopLeftRadius: "0.2em",
            borderTopRightRadius: "0.2em",
            objectFit: "cover",
          }}
        />
      </Link>
      <div
        style={{
          margin: "0.2em",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "150px",
            fontSize: "1em",
            fontWeight: "bold",
          }}>
          {capitalizeFirstLetter(product.name)}
        </div>
        <div>
          {product.status ? (
            <Visibility fontSize={"10px"} />
          ) : (
            <VisibilityOff fontSize={"10px"} />
          )}
        </div>
      </div>
    </Paper>
  );
};

export default ProductCardItem;
