import { ThemeProvider } from "@emotion/react";
import {
  Dashboard,
  Person,
  Refresh,
  Settings,
  Menu,
} from "@mui/icons-material";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import React, { useContext } from "react";
import { OrderBadgeState } from "../../../context/HomePageContext/HomePageContext";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";

const BottomNav = (props) => {
  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);
  const [badgeCount, setbadgeCount] = useContext(OrderBadgeState);

  let { onChange, currentSelectedPageIndex } = props;
  return (
    <ThemeProvider theme={themeValues}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}>
        <BottomNavigation
          showLabels
          sx={{
            width: "100%",
          }}
          value={currentSelectedPageIndex}
          onChange={onChange}>
          <BottomNavigationAction
            label='Orders'
            value='orders'
            icon={
              <Badge
                className={
                  badgeCount > 0
                    ? "animate__animated animate__bounce animate__infinite	infinite"
                    : ""
                }
                color='error'
                // variant='dot'
                badgeContent={badgeCount}
                invisible={badgeCount > 0 ? false : true}>
                <Dashboard />
              </Badge>
            }
          />
          <BottomNavigationAction
            label='History'
            value='history'
            icon={<Refresh />}
          />
          <BottomNavigationAction label='Menu' value='menu' icon={<Menu />} />
          <BottomNavigationAction label='Shop' value='shop' icon={<Person />} />
          <BottomNavigationAction
            label='Settings'
            value='settings'
            icon={<Settings />}
          />
        </BottomNavigation>
      </Paper>
    </ThemeProvider>
  );
};

export default BottomNav;
