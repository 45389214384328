import React, { useState, useContext } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  Alert,
  Paper,
  IconButton,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { navigate } from "gatsby-link";

import { signIn } from "../../api/public/auth";
import CustomTextField from "../../components/textfield/CustomTextField";
import useLoggedUser from "../../custom-hooks/useLoggedUser";
import { Box } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useForgotPass from "../forgotpass/useForgotPass";
import "animate.css";
import { TriggerOrderRefresh } from "../../context/OrderPageContext/OrderPageContext";

const validationSchema = yup.object({
  email: yup.string().email("Invalid email"),
  password: yup.string(),
});

const LoginPage = () => {
  const { cacheLoginData } = useLoggedUser();

  const [alertState, setAlertState] = useState({
    severity: "success",
    message: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const { showForgotPass, ForgotPass } = useForgotPass();

  const [refresh, setrefresh] = useContext(TriggerOrderRefresh);


  function saveToReactNativeWebView(payload = {}) {
    if(window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: "LOGIN", 
        payload: payload
      }))
    }
  }

  return (
    <Container>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);

          console.log(data);

          signIn(data.email, data.password)
            .then((response) => {
              if (response.status === 200) {
                response.json().then((result) => {
                  console.log(result)
                  saveToReactNativeWebView(result)
                  if (result?.user?.role != 1) {
                    setSubmitting(false);
                    setAlertState((prevState) => ({
                      ...prevState,
                      severity: "error",
                      message: "This is not a merchant account",
                    }));
                  } else {
                    setSubmitting(false);
                    cacheLoginData(result);

                    setAlertState((prevState) => ({
                      ...prevState,
                      severity: "success",
                      message: "Login successful!",
                    }));
                    setrefresh(!refresh);
                    navigate("/app");

                    let postMessageData = {
                      type: "LOGIN",
                      payload: result,
                    };


                    if (window.ReactNativeWebView?.postMessage) {
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify(postMessageData)
                      );
                    }
                  }
                });
              } else {
                response.json().then((result) => {
                  setAlertState((prevState) => ({
                    ...prevState,
                    severity: "error",
                    message: result.error,
                  }));
                  setSubmitting(false);
                });
              }
            })
            .catch((e) => console.log(e));
        }}>
        {({ handleChange, isSubmitting }) => (
          <Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "15%",
              }}>
              <Box
                sx={{ width: "300px" }}
                className='animate__animated animate__fadeIn'>
                <Typography variant='h2'>Hurray! Let's start</Typography>
              </Box>
              <Paper
                sx={{ width: "300px", padding: "1em" }}
                className='animate__animated animate__fadeIn'>
                <Stack spacing={2}>
                  <CustomTextField
                    name='email'
                    label='Email'
                    type='email'
                    variant='outlined'
                    required
                    onChange={handleChange}
                  />
                  <CustomTextField
                    name='password'
                    label='Password'
                    variant='outlined'
                    type={showPassword ? "text" : "password"}
                    required
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ),
                    }}
                  />
                  {alertState.message.length > 0 ? (
                    <Alert variant='outlined' severity={alertState.severity}>
                      {alertState.message}
                    </Alert>
                  ) : (
                    <div />
                  )}
                  <Button onClick={showForgotPass}>Forgot password?</Button>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress /> : "Login"}
                  </Button>
                  <Button
                    onClick={() => {
                      navigate("/signup");
                    }}>
                    Don't have an account yet? Sign up here
                  </Button>
                </Stack>
              </Paper>
            </div>
          </Form>
        )}
      </Formik>
      <ForgotPass />
    </Container>
  );
};

export default LoginPage;
