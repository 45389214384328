import React, { useState } from "react";

export const HomePageCurrentPageIndex = React.createContext("orders");

export const OrderBadgeState = React.createContext(0);

const HomePageContext = ({ children }) => {
  const [currentSelectedPageIndex, setCurrentPageIndex] = useState("orders");

  const [badgeCount, setbadgeCount] = useState(0);

  return (
    <HomePageCurrentPageIndex.Provider
      value={[currentSelectedPageIndex, setCurrentPageIndex]}>
      <OrderBadgeState.Provider value={[badgeCount, setbadgeCount]}>
        {children}
      </OrderBadgeState.Provider>
    </HomePageCurrentPageIndex.Provider>
  );
};

export default HomePageContext;
