import { ButtonBase, Card } from "@mui/material";
import React from "react";
import capitalizeFirstLetter from "../../../../helpers/capitalize";
import getTimeFromISO from "../../../../helpers/getTimeFromIso";
import "animate.css";

const OrderCard = ({ order, onClick = () => {} }) => {
  let date = new Date(order.when);
  return (
    <ButtonBase onClick={onClick}>
      <Card
        className='animate__animated animate__bounceInLeft'
        sx={{
          position: "relative",
          margin: "0.5em",
          height: "10em",
          width: "10em",
          maxHeight: "10em",
          maxWidth: "10em",
          backgroundColor:
            order?.status == "Not processed" ? "#0286FE" : "#f1f7fd",
        }}>
        <div style={{ margin: "0.5em" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "150px",
                fontSize: "0.9em",
                fontWeight: "bold",
                color: order?.status == "Not processed" ? "white" : "black",
              }}>
              {order?.user?.name}
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                fontWeight: "bold",
                fontSize: "0.9em",
                color: order?.status == "Not processed" ? "white" : "black",
              }}>
              {`${getTimeFromISO(order?.when)}`}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "2%",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                color: order?.status == "Not processed" ? "white" : "black",
              }}>
              {capitalizeFirstLetter(order?.products[0]?.name)}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                color: order?.status == "Not processed" ? "white" : "black",
              }}>
              See more...
            </div>
          </div>
        </div>
      </Card>
    </ButtonBase>
  );
};

export default OrderCard;
