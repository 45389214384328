import React from 'react'
import { Dialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const FullScreenDialog = ({ children, open, onClose = () => {} }) => {
	return <Dialog fullScreen open={open}>
		<AppBar style={{
			position: 'relative'
		}}>
			<Toolbar>
				<IconButton edge="start" color="inherit" aria-label="close" onClick={onClose}>
					<CloseIcon />
				</IconButton>
				
			</Toolbar>
		</AppBar>
		{children}
	</Dialog>
}

export default FullScreenDialog