import React from "react";
import OrderCard from "../components/OrderCard";
import "../../../../styles/css/HorizaontalCardList.css";
import useOngoingOrderDetailDialog from "../dialogs/useOngoingOrderDetailDialog";

const OrderList = ({ orders }) => {
  const [
    showOngoingOrderDetail,
    closeOngoingOrderDetail,
    OngoingOrderDetailDialog,
  ] = useOngoingOrderDetailDialog();

  return (
    <>
      <div className='horizontallist scrollbar-hidden'>
        {orders.map((order) => {
          return (
            <OrderCard
              order={order}
              onClick={() => {
                showOngoingOrderDetail(order);
              }}
            />
          );
        })}
      </div>
      <OngoingOrderDetailDialog />
    </>
  );
};

export default OrderList;
