import React, { useState, useEffect, useContext } from "react";

import { getAllShopOrdersPaginated } from "../../../api/public/order";
import "./history.css";
import TopBar from "../TopBar/TopBar";

import CenteredProgress from "../../progress/centeredProgress";
import "animate.css";
import HistoryList from "./components/HistoryList";
import ErrorCard from "../../misc/ErrorCard";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";

const HistoryPage = () => {
  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);

  const [page, setPage] = useState(1);

  const [history, setHistory] = useState([]);

  const [state, setstate] = useState("loading");

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    setIsLoadingMore(true);
    getAllShopOrdersPaginated({ page: page, limit: 10 })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((orders) => {
            setHistory((prevState) => [...prevState, ...orders]);
            setstate("loaded");
            setIsLoadingMore(false);
          });
        } else {
          response.json().then((orders) => {
            console.log(orders);
            setstate("error");
            setIsLoadingMore(false);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [page]);

  const handleOnLoadMore = () => {
    let currentPage = page;
    setPage(currentPage + 1);
  };

  // if (!history) {
  //   return <CenteredProgress />;
  // }

  const PageState = (state) => {
    switch (state) {
      case "loading":
        return <CenteredProgress />;
      case "loaded":
        return (
          <HistoryList
            history={history}
            onLoadMore={handleOnLoadMore}
            isLoadingMore={isLoadingMore}
          />
        );
      case "error":
        return <ErrorCard />;
      default:
        return <ErrorCard />;
    }
  };

  return (
    <ThemeProvider theme={themeValues}>
      <CssBaseline />
      <div style={{ padding: "0" }}>
        <TopBar title={"History"} subtitle='View all your previous orders' />
        {PageState(state)}
      </div>
    </ThemeProvider>
  );
};

export default HistoryPage;
