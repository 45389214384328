import React, { useState, useRef } from "react";
import { Close } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { updateUserPhoto } from "../../../../api/public/user";
import useSparkleSnackbar from "../../../../custom-hooks/useSparkleSnackbar";
import { Box } from "@mui/system";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useEditProfilePicture() {
  const [open, setopen] = useState(false);

  const [user, setuser] = useState({});

  const showEditProfilePicture = (userdata) => {
    setopen(true);
    setuser(userdata);
    console.log(userdata);
  };

  const closeEditProfilePicture = () => {
    setopen(false);
  };

  const EditProfilePicture = () => {
    const inputFile = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [selectedImage, setselectedImage] = useState(user?.photo);

    const [selectedImageFile, setselectedImageFile] = useState(user.profile);

    const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackbar();

    const { cacheUser } = useLoggedUser();

    const fileSelectedHandler = (event) => {
      //console.log(event.target.files[0]);
      if (event.target.files[0]) {
        setselectedImage(URL.createObjectURL(event.target.files[0]));
        setselectedImageFile(event.target.files[0]);
      }
    };

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={closeEditProfilePicture}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={closeEditProfilePicture}
              aria-label='close'>
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Update profile picture
            </Typography>
            <Button
              autoFocus
              color='inherit'
              disabled={isSubmitting}
              onClick={() => {
                setIsSubmitting(true);
                updateUserPhoto(selectedImageFile)
                  .then((response) => {
                    if (response.status === 200) {
                      response?.json().then((result) => {
                        console.log(result);
                        cacheUser(result);
                        showSnackbar("Image updated", "success");
                        setIsSubmitting(false);
                      });
                    } else {
                      response?.json().then((result) => {
                        showSnackbar(result.error, "error");
                        setIsSubmitting(false);
                      });
                    }
                  })
                  .catch((e) => console.log(e));
              }}>
              {isSubmitting ? "loading" : "save"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            <Box sx={{ width: "300px" }}>
              <Stack spacing={2}>
                {selectedImage?.length > 0 ? (
                  <Avatar
                    src={selectedImage}
                    alt='selected-image'
                    sx={{ width: 300, height: 300 }}>
                    {/* <LazyLoadImage
                      src={selectedImage}
                      alt='selected-image'
                      effect={"black-and-white"}
                    /> */}
                  </Avatar>
                ) : (
                  <Avatar sx={{ width: 300, height: 300 }}>
                    {user?.name?.substring(0, 2)?.toLocaleUpperCase()}
                  </Avatar>
                )}
                <input
                  type='file'
                  id='file'
                  ref={inputFile}
                  onChange={fileSelectedHandler}
                  style={{ display: "none" }}
                />
                <Button
                  variant='contained'
                  onClick={() => {
                    inputFile.current.click();
                  }}>
                  Upload Picture
                </Button>
              </Stack>
            </Box>
          </div>
        </DialogContent>
        <SparkleSnackBar />
      </Dialog>
    );
  };

  return [showEditProfilePicture, closeEditProfilePicture, EditProfilePicture];
}
