import { Close, ContentPasteOutlined } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Stack,
  Paper,
  Chip,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { getCategories } from "../../../api/public/categories";
import CustomMultiLineTextField from "../../textfield/CustomMultiLineTextField";
import CustomTextField from "../../textfield/CustomTextField";
import ListItem from "../../cards/ListItem";
import { updateProduct } from "../../../api/public/product";
import useSparkleSnackbar from "../../../custom-hooks/useSparkleSnackbar";
import CustomCheckbox from "../../checkbox";

import ProductOptions from '../../ProductOptions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useEditProductDialog() {
  const [open, setOpen] = useState(false);

  const [product, setproduct] = useState({});


  const [forPets, toggleForPets] = useState(false)


  const showEditProductDialog = (product) => {
    setOpen(true);
    setproduct(product);
    console.log(product);

    if(product?.isForPets){
      toggleForPets(product.isForPets)
    }
  };

  const closeEditProductDialog = () => {
    setOpen(false);
  };

  const EditProductDialog = (props) => {
    const { additionalAction } = props;

    const productSize = useRef(null)
    const productAddons = useRef(null)

    const [selectedCategories, setselectedCategories] = useState([]);

    const [categories, setcategories] = useState([]);

    const [showSnackbar, closeSnackBar, SparkleSnackBar] = useSparkleSnackbar();

    function isCategorySelected(category) {
      let result = false;
      for (let index = 0; index < selectedCategories.length; index++) {
        if (category._id === selectedCategories[index]._id) {
          result = true;
        }
      }

      return result;
    }

    function addToSelectedCategoriesHandler(category) {
      if (isCategorySelected(category)) {
        let newList = selectedCategories.filter(
          (item) => item._id !== category._id
        );
        setselectedCategories(newList);
        return;
      }
      setselectedCategories((prevState) => [...prevState, category]);
    }

    function collectCategoryId() {
      let arr = [];
      for (let index = 0; index < selectedCategories.length; index++) {
        arr.push(selectedCategories[index]._id);
      }
      return arr;
    }

    useEffect(() => {
      getCategories().then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            //console.log(result);
            setcategories([...result]);
          });
        }
      });
    }, []);

    useEffect(() => {
      setselectedCategories((prevState) => [...prevState, product?.category]);
    }, [categories]);

    return (
      <Dialog
        open={open}
        onClose={closeEditProductDialog}
        TransitionComponent={Transition}
        fullScreen>
        <Formik
          initialValues={{
            name: product?.name,
            price: product?.price,
            quantity: product?.quantity,
            description: product?.description,
            ingredients: product?.ingredients,
            discount: product?.discount ? product.discount : 0
          }}
          onSubmit={(data, { setSubmitting }) => {
            //setSubmitting(true);

            let productData = {
              ...data,
              discount: data.discount.length > 0 ? data.discount : "0",
              category: collectCategoryId()[0],
              sizes: productSize.current.getProductSizes(),
              addons: productAddons.current.getProductSizes(),
              isForPets: forPets,
            };


            updateProduct(product, productData)
              .then((response) => {
                if (response.status === 200) {
                  additionalAction();
                  closeEditProductDialog();
                  setSubmitting(false);
                } else {
                  showSnackbar("Something went wrong", "error");
                  setSubmitting(false);
                }
              })
              .catch((e) => console.log(e));

            setSubmitting(false);
          }}>
          {({ isSubmitting }) => (
            <Form>
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={closeEditProductDialog}
                    aria-label='close'>
                    <Close />
                  </IconButton>
                  <Typography
                    sx={{ ml: 2, flex: 1 }}
                    variant='h6'
                    component='div'>
                    Edit Product
                  </Typography>
                  <Button
                    color='inherit'
                    type='submit'
                    disabled={isSubmitting}>
                    {isSubmitting ? "loading" : "save"}
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Stack spacing={2}>
                  <CustomCheckbox value = {forPets} onClick = {() => {
                    toggleForPets(!forPets)
                  }}/>
                  <CustomTextField
                    name='name'
                    label='Product name'
                    variant='outlined'
                  />
                  <CustomTextField
                    name='price'
                    label='Price'
                    variant='outlined'
                  />
                  <CustomTextField
                    name='quantity'
                    label='No. in stock'
                    variant='outlined'
                  />
                  <CustomTextField
                    name='discount'
                    label='Discount - Put exact amount or percentage ( e.g P10 or 3% ). Leave blank if not applicable'
                    variant='outlined'
                    required={false}
                  />
                  <CustomMultiLineTextField
                    name='description'
                    label='Description'
                    variant='outlined'
                    multiline
                    rows={3}
                  />
                  <CustomMultiLineTextField
                    name='ingredients'
                    label='Ingredients'
                    variant='outlined'
                    multiline
                    rows={3}
                  />
                  <Divider />
                  <Typography>Uy may sizes yung product mo? Lagay mo na</Typography>
                  <ProductOptions type = 'sizes' options={product?.sizes ? product.sizes : []} label="Size" value="Price" ref={productSize} />
                  <Typography>Uy may addons yung product mo? Lagay mo din yan!</Typography>
                  <ProductOptions type = 'addons' options={product?.addons ? product.addons : []} label="Add-on" value="Price" ref={productAddons} />
                  <Paper
                    sx={{
                      p: 0.5,
                    }}>
                    <Typography>Select categories for this product</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        listStyle: "none",
                        p: 0.5,
                        m: 0,
                      }}
                      component='ul'>
                      {categories.map((category) => {
                        return (
                          <ListItem>
                            <Chip
                              label={category.name}
                              variant={
                                isCategorySelected(category)
                                  ? "filled"
                                  : "outlined"
                              }
                              color={
                                isCategorySelected(category)
                                  ? "primary"
                                  : "info"
                              }
                              onClick={() => {
                                addToSelectedCategoriesHandler(category);
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </Box>
                  </Paper>
                </Stack>
              </DialogContent>
            </Form>
          )}
        </Formik>
        <SparkleSnackBar />
      </Dialog>
    );
  };

  return [showEditProductDialog, EditProductDialog];
}
