import React, { useState } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { StandaloneSearchBox } from 'react-google-maps/lib/components/places/StandaloneSearchBox'

let ref
let searchBoxRef
const MapInternals = withScriptjs(withGoogleMap(({ onPointingLocation }) => {
	let [markerLocation, setMarkerLocation] = useState({ lat: -34.397, lng: 150.644 })
	let [searchedLocation, setSearchedLocation] = useState('')

	return <GoogleMap
		options={{
			streetViewControl: false,
			disableDefaultUI: true
		}}
		onDrag={() => {
			console.log(ref.getCenter().lat())
		
			//geolocation here 
			setMarkerLocation({ lat: ref.getCenter().lat(), lng: ref.getCenter().lng() })
		}}
		onDragEnd={() => {
			let geocoder = new window.google.maps.Geocoder()
			
			geocoder.geocode({location: ref.getCenter()})
			.then(response => {
				if(response.results[0]){
					let result = response.results[0]
					
					setSearchedLocation(`${result.formatted_address}`)
					onPointingLocation({
						location: markerLocation, 
						address: `${result?.name ? result.name : ''} ${result.formatted_address}`
					})
				}
			}).catch(err => {
				onPointingLocation({
					location: {}, 
					address: ''
				})
			})
		}}
		ref={(map) => {
			if (map)
				ref = map
		}}
		//center = {markerLocation}
		defaultZoom={17}
		defaultCenter={markerLocation}
	>
		<StandaloneSearchBox
			ref={(searchbox) => {
				if (searchbox)
					searchBoxRef = searchbox
			}}
			onPlacesChanged={(e) => {
				console.log('places changed')
				let searched = searchBoxRef.getPlaces()[0]
				setSearchedLocation(`${searched?.name ? searched.name : ''}  ${searched.formatted_address}`)
				if(ref){
					ref.panTo(searchBoxRef.getPlaces()[0].geometry.location)
					console.log(ref)	
				}

				setMarkerLocation({
					lat: searchBoxRef.getPlaces()[0].geometry.location.lat(),
					lng: searchBoxRef.getPlaces()[0].geometry.location.lng()
				})
				console.log({
					lat: searchBoxRef.getPlaces()[0].geometry.location.lat(),
					lng: searchBoxRef.getPlaces()[0].geometry.location.lng()
				})

				onPointingLocation({
					location: markerLocation, 
					address: `${searched?.name ? searched.name : ''}  ${searched.formatted_address}`
				})
			}}
		>
			<input
				type="text"
				placeholder="Enter your location here"
				value = {searchedLocation}
				onChange = {(e) => {
					setSearchedLocation(e.target.value)
				}}
				style={{
					boxSizing: `border-box`,
					border: `1px solid transparent`,
					width: `90%`,
					height: `32px`,
					marginTop: `27px`,
					marginLeft: '5%',
					marginRight: 'auto',
					padding: `0 12px`,
					borderRadius: `3px`,
					boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
					fontSize: `14px`,
					outline: `none`,
					marginBottom: '30px',
					textOverflow: `ellipses`,
				}}
			/>
		</StandaloneSearchBox>
		<Marker position={markerLocation} />
	</GoogleMap>
}
))


const Map = ({ onPointingLocation = () => { } }) => {
	return <MapInternals
		onPointingLocation={onPointingLocation}
		googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCwjH9_I1j2FH89huz-Ld5YVHxNM0mVhn0`}
		loadingElement={<div style={{ height: `100%` }} />}
		containerElement={<div style={{ height: `30em` }} />}
		mapElement={<div style={{ height: `100%` }} />} />
}


export default Map