import React from "react";
import {
  AppBar,
  Button,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { navigate } from "gatsby-link";
import welcomebanner from "../../assets/images/mascot_welcome_logo.png";
import sparkleblue from "../../assets/images/sparkle_logo_blue.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "animate.css";

const OnboardingPage = () => {
  return (
    <Container
      sx={{
        textAlign: "center",
        minHeight: "100%",
      }}>
      {/* APPBAR */}
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "white",
        }}>
        <Toolbar>
          <LazyLoadImage
            src={sparkleblue}
            alt='sparkle-logo'
            effect={"black-and-white"}
            width='100px'
          />
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* HERO */}
      <div>
        <Grid
          container
          spacing={2}
          direction='row'
          alignItems='center'
          justifyContent='center'>
          <Grid item direction='row' justifyContent='center'>
            <LazyLoadImage
              className='animate__animated animate__bounceIn'
              src={welcomebanner}
              alt='welcome-banner'
              effect={"black-and-white"}
              width='90%'
            />
          </Grid>
          <Grid item xs>
            <div
              className='animate__animated animate__fadeIn'
              style={{
                textAlign: "start",
              }}>
              <Typography variant='h3' textAlign='center' fontFamily='Visby'>
                Be a star merchant
              </Typography>
              <Typography variant='h6' textAlign='center' fontFamily='Visby'>
                Boost your online selling... the easiest way! Discover how your
                home-made specialty products meet millions of customers
              </Typography>
              <Button
                variant='contained'
                sx={{
                  width: "100%",
                  marginTop: "2em",
                }}
                onClick={() => {
                  navigate("/app");
                }}>
                Get started
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* FOOTER */}
      <footer
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}>
        Copyright @ 2021 by Sparkle. All rights reserved.
      </footer>
    </Container>
  );
};

export default OnboardingPage;
