import { API } from "../../api-config";

export const getCategories = async () => {
  return await fetch(`${API}/category/list/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};
