import React, { useState } from "react";
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import CustomTextField from "../textfield/CustomTextField";
import { DialogTitle } from "@material-ui/core";
import { resetPassword } from "../../api/public/auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useForgotPass() {
  const [open, setopen] = useState(false);

  const showForgotPass = () => {
    setopen(true);
  };

  const closeForgotPass = () => {
    setopen(false);
  };

  const ForgotPass = () => {
    const [alertState, setAlertState] = useState({
      show: false,
      message: " This is an alert — check it out!",
      status: "warning",
    });
    return (
      <Dialog
        open={open}
        onClose={closeForgotPass}
        TransitionComponent={Transition}>
        <DialogContent>
          <Formik
            initialValues={{}}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              resetPassword(data.email)
                .then((response) => {
                  if (response?.status === 200) {
                    response.json().then((result) => {
                      setAlertState({
                        show: true,
                        message:
                          "Link has been sent to your email, use it to change password!",
                        status: "success",
                      });
                      setSubmitting(false);
                    });
                  } else {
                    response.json().then((result) => {
                      setAlertState({
                        show: true,
                        message: `${result.message}`,
                        status: "error",
                      });
                      setSubmitting(false);
                    });
                  }
                })
                .catch((e) => console.log(e));
            }}>
            {({ isSubmitting }) => (
              <Form>
                <Stack spacing={2}>
                  <Typography>
                    Please type and submit your email, we will send you a
                    password reset link
                  </Typography>
                  <CustomTextField
                    name='email'
                    label='Email'
                    variant='outlined'
                    type='email'
                  />
                  <Collapse in={alertState.show}>
                    <Alert severity={alertState.status}>
                      {alertState.message}
                    </Alert>
                  </Collapse>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={isSubmitting}>
                    {isSubmitting ? "Loading" : "Submit"}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  };

  return { showForgotPass, closeForgotPass, ForgotPass };
}
