import { io } from "socket.io-client"
import { SOCKET_URL } from "../api-config"


const socket = io(SOCKET_URL)

socket.on("connect", () => {
  console.log(`You connected with id: ${socket.id}`)
})

export default socket