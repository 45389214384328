import { Card, Container } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";

const NoDataCard = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20%",
      }}>
      <ErrorOutlineIcon sx={{ margin: "0.2em" }} />
      No data found
    </Container>
  );
};

export default NoDataCard;
