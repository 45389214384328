import React, { useState } from "react";

export const ShopData = React.createContext(null);

const ShopPageContext = ({ children }) => {
  const [shopData, setShopData] = useState(null);

  return (
    <ShopData.Provider value={[shopData, setShopData]}>
      {children}
    </ShopData.Provider>
  );
};

export default ShopPageContext;
