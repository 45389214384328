import React, { useState } from 'react'
import TermsConditionsDialog from './termsConditionsDialog'
import PrivacyPolicyDialog from './privacyPolicyDialog'
import ForwardTogetherAgreementDialog from './forwardTogetherAgreement'
import CustomCheckbox from '../../checkbox'


const Agreements = ({ agreed = false, toggleAgreed = () => { } }) => {
	const [agreementDialogState, setAggreementDialogState] = useState({
		forwardTogetherDialog: false,
		privacyPolicyDialog: false,
		termsConditionsDialog: false,
	})

	const closeAgreementDialogs = () => {
		setAggreementDialogState({
			forwardTogetherDialog: false,
			privacyPolicyDialog: false,
			termsConditionsDialog: false
		})
	}

	return <>
		<CustomCheckbox value={agreed} onClick={toggleAgreed} label={<>I agree to the<a
			style={{
				color: 'blue'
			}}

			onClick={() => {
				setAggreementDialogState({
					forwardTogetherDialog: true,
					privacyPolicyDialog: false,
					termsConditionsDialog: false
				})
			}}> Terms and Conditions </a>, <a
				style={{
					color: 'blue'
				}}
				onClick={() => {
					setAggreementDialogState({
						forwardTogetherDialog: false,
						privacyPolicyDialog: true,
						termsConditionsDialog: false
					})
				}}>Forward Together Agreement </a>, <a
					style={{
						color: 'blue'
					}}
					onClick={() => {
						setAggreementDialogState({
							forwardTogetherDialog: false,
							privacyPolicyDialog: false,
							termsConditionsDialog: true
						})
					}}>Privacy Policy </a> </>} />
		<TermsConditionsDialog open={agreementDialogState.termsConditionsDialog} onClose={closeAgreementDialogs} />
		<PrivacyPolicyDialog open={agreementDialogState.privacyPolicyDialog} onClose={closeAgreementDialogs} />
		<ForwardTogetherAgreementDialog open={agreementDialogState.forwardTogetherDialog} onClose={closeAgreementDialogs} /></>

}

export default Agreements