import React, { useState, useImperativeHandle, forwardRef } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography'
/*
	product size element
{
	name: 'small'
	price: 50
}

*/


const ProductSize = ({name = '', price = '', listIndex, addToList = () => { }, removeToList = () => { } }, ref) => {
	let [productSize, setProductSize] = useState(name)
	let [productPrice, setProductPrice] = useState(price)

	function handleProductSizeChange(e) {
		addToList(listIndex, { name: e.target.value, price: productPrice })
		setProductSize(e.target.value)
	}

	function handleProductPriceChange(e) {
		addToList(listIndex, { name: productSize, price: Number(e.target.value) })
		setProductPrice(Number(e.target.value))
	}

	function removeProductFromList() {
		removeToList(listIndex)
	}

	return <Grid container>
		<Grid item xs={5}>
			<TextField id="standard-size" variant="standard" value={productSize} onChange={handleProductSizeChange} />
		</Grid>
		<Grid item xs={5}>
			<TextField id="standard-price" variant="standard" value={productPrice} onChange={handleProductPriceChange} />
		</Grid>
		<Grid item xs={2}>
			<IconButton onClick={removeProductFromList}>
				<CloseIcon />
			</IconButton>
		</Grid>
	</Grid>
}

const ProductSizes = ({ type = 'addon', options = [], label, value }, ref) => {
	let [productSizes, setProductSizes] = useState(options)

	useImperativeHandle(ref, () => ({
		getProductSizes: () => {
			return productSizes.length > 0 ?  productSizes.filter((productSizes) => {
				return productSizes.name.trim().length > 0 && productSizes.price.toString().trim().length > 0
			}) : []
		},
	}))
	function addSize() {
		setProductSizes([...productSizes, { name: '', price: '' }])
	}

	function addToList(index, data) {
		let update = [...productSizes.slice(0, index), data, ...productSizes.slice(index + 1, productSizes.length)]
		setProductSizes(update)
	}

	function removeToList(index) {
		let update = [...productSizes.slice(0, index), ...productSizes.slice(index + 1, productSizes.length)]
		setProductSizes(update)
	}

	return <Stack> <Grid container spacing={2}>
		<Grid item xs={5}>
			<Typography variant='h6' component='h1'>
				{label}
			</Typography>
		</Grid>
		<Grid item xs={5}>
			<Typography variant='h6' component='h1'>
				{value}
			</Typography>
		</Grid>
	</Grid>
		{productSizes.length ? productSizes.map((productSize, index) => {
			return <ProductSize addToList={addToList} removeToList={removeToList} {...productSize} key={index} listIndex={index} />
		}) : <></>}

		<Box sx={{
			marginTop: '1em	'
		}}>
			<Button size='small' variant="outlined" onClick={addSize}>{type === 'addons' ? 'Create Add-on': 'Create size variant'}</Button>
		</Box>

	</Stack>
}


export default forwardRef(ProductSizes)