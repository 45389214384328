import { Person } from "@mui/icons-material";
import { Avatar, ButtonBase, Typography } from "@mui/material";
import React from "react";

const SettingsItem = ({
  icon = <Person />,
  title = "Your text here",
  onClick = () => {},
}) => {
  return (
    <div>
      <ButtonBase
        onClick={onClick}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "300px",
        }}>
        <div
          style={{ display: "flex", alignItems: "center", padding: "0.5em" }}>
          {icon}
          <Typography p={2} fontFamily='Visby' fontWeight='bold'>
            {title}
          </Typography>
        </div>
      </ButtonBase>
    </div>
  );
};

export default SettingsItem;
