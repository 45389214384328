import React from "react";
import { useField } from "formik";
import { TextField } from "@mui/material";

const CustomMultiLineTextField = ({
  type,
  label,
  placeholder,
  InputProps,
  defaultValue,
  rows,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      label={label}
      variant='outlined'
      required
      fullWidth
      type={type}
      multiline
      rows={rows}
      placeholder={placeholder}
      defaultValue={defaultValue}
      {...field}
      helperText={errorText}
      error={!!errorText}
      InputProps={InputProps}
    />
  );
};

export default CustomMultiLineTextField;
