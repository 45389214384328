import React, { useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import TabPanel from "../../../misc/TabPanel";
import OrderList from "../orderlists/OrderList";
import alarm from '../../../../assets/sounds/egg_alarm.mp3'

import Sound from 'react-sound'

const TodayTabPage = (props) => {
  const [playing, setPlaying] = useState('STOPPED')
  const {
    value,
    index,
    newOrders,
    acceptedOrders,
    preparingOrders,
    forpickupOrders,
  } = props;

  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (newOrders.length > 0)
        setPlaying('PLAYING')
      else
        setPlaying('STOPPED')
    }

  }, [newOrders])

  return (
    <div>

      <Sound url={alarm}
        autoLoad={true}
        loop={true}
        playStatus={playing}
      />
      <TabPanel value={value} index={index}>
        <Accordion expanded={true}>
          <AccordionSummary>
            <b>New orders</b>
          </AccordionSummary>
          <AccordionDetails>
            <OrderList orders={newOrders} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true}>
          <AccordionSummary>
            <b>Accepted</b>
          </AccordionSummary>
          <AccordionDetails>
            <OrderList orders={acceptedOrders} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true}>
          <AccordionSummary>
            <b>Preparing</b>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <OrderList orders={preparingOrders} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={true}>
          <AccordionSummary>
            <b>Ongoing Deliveries</b>
          </AccordionSummary>
          <AccordionDetails>
            {" "}
            <OrderList orders={forpickupOrders} />
          </AccordionDetails>
        </Accordion>
      </TabPanel>
    </div>
  );
};

export default TodayTabPage;
