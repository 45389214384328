import React from "react";
import TabPanel from "../../../misc/TabPanel";

const SchedTabPage = (props) => {
  const { value, index } = props;
  return (
    <TabPanel value={value} index={index}>
      Coming soon
    </TabPanel>
  );
};

export default SchedTabPage;
