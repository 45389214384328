import React from "react";

import { Grid, List } from "@material-ui/core";

import StatusChip from "../../../Chips/StatusChip";

import moment from "moment";

function OrderHistoryCard({ data, showOrderDetails }) {
  return (
    <div
      className='animate__animated animate__fadeIn'
      onClick={() => {
        showOrderDetails(data);
      }}
      style={{
        borderRadius: "15px",
        border: "1px solid gray",
        padding: ".5rem",
        margin: ".5em",
      }}>
      <Grid container style={{ paddingLeft: "10px" }}>
        <Grid item xs={8}>
          <div
            style={{
              fontFamily: "visby",
              fontWeight: "bold",
              margin: "0",
              paddingTop: "5px",
            }}>
            {data?.user?.name}
          </div>
          <div
            style={{
              margin: "0",
              fontSize: "14px",
            }}>
            {moment(data?.when).format("LL")} |{" "}
            {moment(data?.when).format("LT")}
          </div>
          <div
            style={{
              margin: "0",
              fontSize: "14px",
            }}>
            {data?.transaction_id}
          </div>
        </Grid>
        <Grid item xs={4} style={{ paddingRight: "10px" }}>
          <List>
            <StatusChip status={data?.status} />
          </List>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderHistoryCard;
