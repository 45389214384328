import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { updateShop } from "../../../../api/public/shop";
import useSparkleAlert from "../../../alert/useSparkleAlert";
import Map from '../../../map'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export default function useEditShopLocation() {
  const [open, setOpen] = useState(false);

  const showEditShopLocation = () => {
    setOpen(true);
  };

  const closeEditShopLocation = () => {
    setOpen(false);
  };

  const EditShopLocation = () => {
    const [isSubmitting, setisSubmitting] = useState(false);

    const [selectedPlace, setSelectedPlace] = useState(null);

    const [addressData, setaddressData] = useState({
      address: "",
      long: 0.0,
      lat: 0.0,
    });

    const { showSparkleAlert, SparkleAlert } = useSparkleAlert();

    function handleSearchPlaceSelection(params) {
      //console.log(params)

      //Update place search state
      setSelectedPlace(params);

      //get lat lng
      //console.log(params.label)
      geocodeByAddress(params.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Successfully got latitude and longitude", { lat, lng });
          //set map local state
          setaddressData((prevState) => ({
            ...prevState,
            address: params.label,
            lat: lat,
            long: lng,
          }));
        });
    }

    return (
      <Dialog
        disableEnforceFocus
        disablePortal
        open={open}
        onClose={closeEditShopLocation}
        TransitionComponent={Transition}
        fullWidth>
        <DialogContent>
          <Stack spacing={2} m={2}>
            <Typography variant='h6'>
              Type and select your new address
            </Typography>
            <SparkleAlert />
            <div style={{ marginBottom: '2em' }}>
              <Map onPointingLocation={({location, address}) => {
                console.log(location, address)
                setaddressData({
                  address: address, 
                  lat: location.lat, 
                  long: location.lng
                })
              }}/>
            </div>

          </Stack>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => {
            setisSubmitting(true);
            updateShop(addressData)
              .then((response) => {
                if (response.status === 200) {
                  setisSubmitting(false);
                  showSparkleAlert("Address changed success!", "success");
                } else {
                  response?.json()?.then((result) => {
                    setisSubmitting(false);
                    showSparkleAlert(`${result.error}`, "error");
                  });
                }
              })
              .catch((e) => console.log(e));
          }}
            color="primary">
            {isSubmitting ? "Loading" : "Update"}
          </Button>

          <Button onClick = {closeEditShopLocation}>
            Close
            </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return { showEditShopLocation, closeEditShopLocation, EditShopLocation };
}
