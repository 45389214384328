import React, { useState, useContext } from "react";
import { Button, Container, Stack } from "@mui/material";
import { updateOrderStatus } from "../../../../api/public/order";
import useConfirmationDialog from "../../../dialogs/useConfirmationDialog";
import useDeclineOrderDialog from "../dialogs/useDeclineOrderDialog";
import socket from '../../../../api/socket'
import { OrdersLoading, TriggerOrderRefresh } from '../../../../context/OrderPageContext/OrderPageContext'

const OngoingOrderButtonAction = ({ order, additionalActions = () => { } }) => {
  const [showDeclineDialog, closeDeclinetDialog, DeclineDialog] =
    useDeclineOrderDialog();

  const [refresh, setrefresh] = useContext(TriggerOrderRefresh)

  const [isLoading, setIsLoading] = useContext(OrdersLoading)

  const [showConfirmationDialog, ConfirmationDialog] = useConfirmationDialog();

  const [isSubmitting, setSubmitting] = useState(false);

  const buttonText = (status) => {
    switch (status) {
      case "Not processed":
        return "Accept";
      case "Accepted":
        return "Start preparing";
      case "Preparing":
        return "Ready for pick up!";
      case "For-pickup":
        return "Cancel pick up";
      default:
        return "Something went wrong";
    }
  };

  const nextStatus = (status) => {
    switch (status) {
      case "Not processed":
        return "Accepted";
      case "Accepted":
        return "Preparing";
      case "Preparing":
        return "For-pickup";
      default:
        return "Preparing";
    }
  };

  const dialogMessages = (status) => {
    switch (status) {
      case "Not processed":
        return "You are about to accept the order, cancellation is not allowed beyond this point";
      case "Accepted":
        return "Do you wish to proceed?";
      case "Preparing":
        return "Ready for pick up?";
      case "For-pickup":
        return "Cancel pick up?";
      default:
        return "Something went wrong";
    }
  };

  function handleUpdateStatus() {
    console.log('updating')
    setIsLoading(true)
    setSubmitting(true);
    updateOrderStatus(order?._id, nextStatus(order?.status)).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          console.log('now setting state')
          setSubmitting(false);
          setrefresh(!refresh)
          response.json().then((result) => {
            socket.emit(`update-order`, JSON.stringify(result))
          });
        } else {
          response.json().then((result) => {
            console.log(result);
            setSubmitting(false);
            setrefresh(!refresh)
          });
        }
      }
    );
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Button
          variant='contained'
          disabled={isSubmitting}
          onClick={() => {
            showConfirmationDialog({
              message: dialogMessages(order?.status),
              title: "Please confirm",
            });
          }}>
          {isSubmitting ? "Loading" : buttonText(order?.status)}
        </Button>
        {order?.status === "Not processed" ? (
          <Button
            onClick={() => {
              showDeclineDialog(order);
            }}>
            Decline
          </Button>
        ) : (
          <div></div>
        )}
      </Stack>
      <DeclineDialog additionalActions={additionalActions} />
      <ConfirmationDialog
        onConfirm={handleUpdateStatus}
        additionalActions={additionalActions}
      />
    </Container>
  );
};

export default OngoingOrderButtonAction;
