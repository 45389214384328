import React from "react";
import { Divider, Paper } from "@mui/material";

import computeProductPrice from "../../../../helpers/computeProductPrice";

const ProductListItem = ({
  name = "Product name",
  count = "0",
  price = "3.0",
  addons = [],
  discount = "0",
  size = '',
}) => {

  return (
    <Paper
      sx={{
        margin: "0.5em",
        padding: "0.5em",
      }}>
      <div
        style={{
          display: "flex",
        }}>
        <div
          style={{
            fontSize: "20px",
            paddingRight: "15px",
          }}>
          {count}
        </div>
        <div
          style={{
            fontSize: "20px",
          }}>
          {name} <br />
          {size} <br />
          {addons.map(({name}) => {
            return <>{name}</>
          })}
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          fontSize: "20px",
        }}>
        {`P ${computeProductPrice({
          price: price,
          count: count,
          addons: addons,
          discount: discount
        })}.0`}
      </div>
    </Paper >
  );
};

export default ProductListItem;
