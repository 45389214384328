import { useState } from "react";
import SecureLS from "secure-ls";

export default function useLoggedUser() {
  const ls = new SecureLS({ encodingType: "aes" });

  const getToken = () => {
    const data = ls.get("token");
    return data;
  };

  const getUserShop = () => {
    const data = ls.get("shop");
    return data;
  };

  const getUser = () => {
    const data = ls.get("user");
    return data;
  };

  const [token, setToken] = useState(getToken());

  const [userShop, setUserShop] = useState(getUserShop());

  const [user, setUser] = useState(getUser());

  function cacheLoginData(data) {
    ls.set("token", data.token);
    ls.set("user", data.user);
    ls.set("shop", data.shop);
    setToken(data.token);
    setUserShop(data.shop);
    setUser(data.user);
  }

  const cacheToken = (token) => {
    ls.set("token", token);
    setToken(token);
  };

  const cacheShop = (shop) => {
    ls.set("shop", shop);
    setUserShop(shop);
  };

  const cacheUser = (user) => {
    ls.set("user", user);
    setUser(user);
  };

  function removeUserData() {
    ls.remove("token");
    ls.remove("user");
    ls.remove("shop");
  }

  return {
    token,
    userShop,
    user,
    cacheToken,
    cacheShop,
    cacheUser,
    cacheLoginData,
    removeUserData,
  };
}
