import { CircularProgress, Container } from "@mui/material";
import { navigate } from "gatsby-link";
import React from "react";
import useLoggedUser from "../../custom-hooks/useLoggedUser";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { user } = useLoggedUser();

  if (!user && location.pathname !== "/login") {
    navigate("/login");
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
