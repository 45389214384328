//https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Privacy%20Policy.pdf?alt=media&token=9335acfb-ab92-4fec-984c-3dafaca5c8e1
import React from 'react'
import Iframe from 'react-iframe'
import FullScreenDialog from '../../dialogs/fullScreenDialog'

const PrivacyPolicyDialog = ({ open, onClose=  () => {} }) => {
	return <FullScreenDialog open={open} onClose = {onClose}>
		<Iframe width='100%' height='100%' url='https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Privacy%20Policy.pdf?alt=media&token=9335acfb-ab92-4fec-984c-3dafaca5c8e1' />
	</FullScreenDialog>
}

export default PrivacyPolicyDialog