import React from "react";

const LabeledValues = ({ label = "Address", value = "San Diego CA" }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: "0.5em",
      }}>
      <div
        style={{
          fontWeight: "bold",
        }}>
        {label}
      </div>
      <div>{value}</div>
    </div>
  );
};

export default LabeledValues;
