import React, { useState } from "react";
import {
	Dialog,
	DialogContent,
	Divider,
	Slide,
	Typography,
	Box,
	Grid,
} from "@mui/material";
// import LabeledValues from "../../../labels/LabeledValues";
// import numberNullSafety from "../../../../helpers/numberNullSafety";
import { makeStyles } from "@material-ui/core/styles";
// import capitalizeFirstLetter from "../../../../helpers/capitalize";
import StatusChip from "../../../Chips/StatusChip";
import moment from "moment";
import "../history.css";
import computeProductPrice from "../../../../helpers/computeProductPrice";

const useStyles = makeStyles({
	page: {
		marginLeft: "1em",
		fontFamily: "visby",
	},
	margin: {
		marginTop: ".5em",
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function useOrderDetailViewer() {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const [order, setOrder] = useState({});

	const showOrderDetails = (targetOrder) => {
		setOpen(true);
		setOrder(targetOrder);
		console.log(targetOrder);
	};
	console.log(order);

	const closeOrderDetails = () => {
		setOpen(false);
	};

	// const ProductItem = ({ product }) => {
	// 	return (
	// 		<div style={{ margin: "0.5em" }}>
	// 			<div
	// 				style={{
	// 					display: "flex",
	// 					justifyContent: "space-between",
	// 				}}
	// 			>
	// 				<div style={{ marginRight: "0.5em", fontWeight: "bold" }}>
	// 					{product.count}
	// 				</div>
	// 				<div>{capitalizeFirstLetter(product.name)}</div>
	// 			</div>
	// 			<div>{`P ${product.price * product.count}.0`}</div>
	// 		</div>
	// 	);
	// };

	// function totalProducts(products) {
	// 	let total = 0;
	// 	for (let index = 0; index < products?.length; index++) {
	// 		total = total + products[index].price * products[index].count;
	// 	}

	// 	return total;
	// }

	const OrderDetails = () => {
		// return (
		//   <Dialog
		//     open={open}
		//     onClose={closeOrderDetails}
		//     TransitionComponent={Transition}>
		//     <DialogContent>
		//       <LabeledValues
		//         label='Transaction ID: '
		//         value={order.transaction_id}
		//       />
		//       <Divider />
		//       <Typography variant='h6'>Customer</Typography>
		//       <LabeledValues label='Name: ' value={order?.user?.name} />
		//       <LabeledValues label='Phone: ' value={order?.user?.phone} />
		//       <LabeledValues label='Address: ' value={order?.user?.address} />
		//       <Divider />
		//       <Typography variant='h6'>Products</Typography>
		//       {order?.products?.map((product) => {
		//         return <ProductItem key={product._id} product={product} />;
		//       })}
		//       <Divider />
		//       <LabeledValues
		//         label='Grand Total: '
		//         value={totalProducts(order?.products)}
		//       />
		//     </DialogContent>
		//   </Dialog>
		// );
		return (
			<Dialog
				open={open}
				onClose={closeOrderDetails}
				TransitionComponent={Transition}
			>
				<DialogContent>
					<div className={classes.page}>
						<Typography variant="h6">
							<Box
								fontWeight="fontWeightBold"
								m={1}
								className={classes.text}
							>
								Order Details{" "}
								<StatusChip status={order?.status} />
							</Box>
						</Typography>
						<div>
							<Grid container spacing={1}>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											ID:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>
											{order?.transaction_id}
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Order for:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>
											{moment(order?.when)
												.subtract(10, "days")
												.calendar()}
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Name:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.user?.name}</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Contact:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.user?.phone}</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Pickup Address:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.shop?.address}</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Delivery Address:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.user?.address}</Box>
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1}>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Payment:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.paymentType}</Box>
									</Typography>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Notes:
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3}>
									<Typography variant="subtitle2">
										<Box pl={1}>{order?.deliveryNotes}</Box>
									</Typography>
								</Grid>
							</Grid>
							<Grid>
								<Typography variant="subtitle2">
									<Box
										fontWeight="fontWeightBold"
										pl={1}
										className={classes.text}
									>
										Products
									</Box>
								</Typography>
							</Grid>
							{order?.products?.map((product) => {
								return (
									<>
										<Grid container spacing={1}>
											<Grid item xs={8}>
												<Typography variant="subtitle2">
													<Box pl={1}>
														{product.count}{" "}
														{product?.size}{" "}
														{product.name} -
														{product?.addons && <> <br />{product.addons.map(({ name }) => <>{name}<br /></>)} </>}
													</Box>
												</Typography>
											</Grid>
											<Grid item xs={4}>
												<Typography variant="subtitle2">
													<Box pl={1}>
														P{" "}
														{computeProductPrice(product)}
													</Box>
												</Typography>
											</Grid>
										</Grid>
									</>
								);
							})}
							<Box pt={0.5} pb={0.5}>
								<Divider
									variant="middle"
									className={classes.margin}
								/>
							</Box>
							<Grid container spacing={1}>
								<Grid item xs={8}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Delivery Fee
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="subtitle2">
										<Box pl={1}>P 0.0</Box>
									</Typography>
								</Grid>
							</Grid>
							<Box>
								<Divider
									variant="middle"
									className={classes.margin}
								/>
							</Box>
							<Grid container spacing={1}>
								<Grid item xs={8}>
									<Typography variant="subtitle2">
										<Box
											fontWeight="fontWeightBold"
											pl={1}
											className={classes.text}
										>
											Total
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="subtitle2">
										<Box pl={1}>P {order?.amount}.0</Box>
									</Typography>
								</Grid>
							</Grid>
							<Box pb={5}></Box>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		);
	};

	return { showOrderDetails, closeOrderDetails, OrderDetails };
}
