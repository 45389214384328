import React, { useState, useRef } from "react";
import {
	Dialog,
	DialogContent,
	Slide,
	Button,
	Typography,
	Stack,
	IconButton,
	AppBar,
	Toolbar,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import { Close, HighlightOff } from "@mui/icons-material";
import { addAdditionalProductPictures } from "../../../api/public/product";
import useSparkleSnackbar from "../../../custom-hooks/useSparkleSnackbar";
import {
	generateUploadUrl,
	uploadImage,
} from "../../../api/public/product/index";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function useAddProductImageDialog() {
	const [open, setOpen] = useState(false);

	const [product, setProduct] = useState({});

	const showAddProductImageDialog = (targetProduct) => {
		setOpen(true);
		setProduct(targetProduct);
	};

	const closeAddProductImageDialog = () => {
		setOpen(false);
	};

	const AddProductImageDialog = () => {
		const inputFile = useRef(null);

		const [isSubmitting, setIsSubmitting] = useState(false);

		const [selectedImagesList, setselectedImagesList] = useState([]);

		const [selectedImageFiles, setselectedImageFiles] = useState([]);

		const [showSnackbar, closeSnackbar, SparkleSnackBar] = useSparkleSnackbar();

		const fileSelectedHandler = (event) => {
			//console.log(event.target.files[0]);
			if (event.target.files[0]) {
				setselectedImagesList((prevState) => [
					...prevState,
					URL.createObjectURL(event.target.files[0]),
				]);
				setselectedImageFiles((prevState) => [
					...prevState,
					event.target.files[0],
				]);
			}
		};

		return (
			<Dialog
				open={open}
				onClose={closeAddProductImageDialog}
				fullScreen
				TransitionComponent={Transition}
			>
				<AppBar elevation={0} sx={{ position: "relative" }}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={closeAddProductImageDialog}
							aria-label="close"
						>
							<Close />
						</IconButton>
						<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
							Add Additional Pictures
						</Typography>
						<Button
							autoFocus
							color="inherit"
							type="submit"
							disabled={isSubmitting}
							onClick={() => {
								setIsSubmitting(true);
                console.log(selectedImageFiles)

								addAdditionalProductPictures(
									selectedImageFiles,
									product?._id,
								)
									.then((response) => {
										if (response.status === 200) {
											response?.json().then((e) => {
												console.log(e);
												showSnackbar(
													"Upload success, please refresh to see the changes",
													"success"
												);
												setIsSubmitting(false);
											});
										} else {
											response?.json().then((e) => {
												showSnackbar(e.error, "error");
												setIsSubmitting(false);
											});
										}
									})
									.catch((e) => console.log(e));
							}}
						>
							{isSubmitting ? "loading" : "save"}
						</Button>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<div>
						<Stack direction="row">
							<Button
								variant="contained"
								onClick={() => {
									inputFile.current.click();
								}}
							>
								Upload a photo
							</Button>
						</Stack>
						<input
							type="file"
							id="file"
							ref={inputFile}
							onChange={fileSelectedHandler}
							style={{ display: "none" }}
						/>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
							}}
						>
							{selectedImagesList.map((image, index) => {
								return (
									<div
										style={{
											position: "relative",
										}}
									>
										<IconButton
											sx={{
												position: "absolute",
												top: "1%",
												right: "1%",
												// color: "#0286FE",
												color: "red",
												zIndex: "999",
											}}
											onClick={() => {
												console.log(image);
												let newList = selectedImagesList.filter(
													(item) => item !== image
												);
												setselectedImagesList([...newList]);

												let newFileList = selectedImageFiles.filter(
													(item) => item !== image
												);
												setselectedImageFiles([...newFileList]);
											}}
										>
											<HighlightOff />
										</IconButton>

										<div
											style={{
												margin: "0.2em",
											}}
										>
											<LazyLoadImage
												src={image}
												alt="product-image"
												height="100px"
												width="100px"
												effect={"black-and-white"}
												style={{
													borderRadius: "0.5em",
													objectFit: "cover",
													margin: "0.2em",
												}}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</DialogContent>
				<SparkleSnackBar />
			</Dialog>
		);
	};

	return {
		showAddProductImageDialog,
		closeAddProductImageDialog,
		AddProductImageDialog,
	};
}
