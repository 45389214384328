import { API } from "../../api-config";
import SecureLS from "secure-ls";

export const getShop = async (shopId) => {
  return await fetch(`${API}/shop/${shopId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateShop = async (shopNewData) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");
  const shop = ls.get("shop");

  return await fetch(`${API}/shop/${shop._id}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(shopNewData),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const updateShopStatus = async (shopId, status) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/shop/${shopId}/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: status }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const uploadShopBanner = async (newBannerImage) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const shop = ls.get("shop");

  const formdata = new FormData();

  formdata.append("file", newBannerImage);

  return await fetch(`${API}/shop/banner/${shop._id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const uploadShopLogo = async (newShopLogo) => {
  const ls = new SecureLS({ encodingType: "aes" });

  const token = ls.get("token");
  const shop = ls.get("shop");

  const formdata = new FormData();

  formdata.append("file", newShopLogo);

  return await fetch(`${API}/shop/logo/${shop._id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formdata,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};
