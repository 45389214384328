import { Box } from "@mui/system";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import "animate.css";
import useOrderDetailViewer from "../dialogs/useOrderDetailViewer";
import NoDataCard from "../../../misc/NoDataCard";
import OrderHistoryCard from "./OrderHistoryCard";
import { Button, Container } from "@mui/material";

const useStyles = makeStyles({
  header: {
    fontFamily: "visby",
    fontWeight: "bold",
    margin: "0",
    paddingTop: "5px",
  },
});

const HistoryList = ({
  history = [],
  onLoadMore = () => {},
  isLoadingMore = false,
}) => {
  const classes = useStyles();

  const { showOrderDetails, closeOrderDetails, OrderDetails } =
    useOrderDetailViewer();

  console.log(history);

  return (
    <div>
      <Box pt={5}>
        {history.length <= 0 ? (
          <NoDataCard />
        ) : (
          <div>
            {history.map((data) => (
              <OrderHistoryCard
                data={data}
                showOrderDetails={showOrderDetails}
              />
            ))}
            <Container
              style={{
                padding: 10,
              }}>
              <Button fullWidth onClick={onLoadMore}>
                {isLoadingMore ? "Loading..." : "Load more"}
              </Button>
            </Container>
          </div>
        )}
      </Box>
      <OrderDetails />
    </div>
  );
};

export default HistoryList;
