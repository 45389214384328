import { CameraAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import NoImage from "../../../../assets/images/no-image.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

const ShopLogoAvatar = ({ shopData, onClick = () => {}, theme }) => {
  return (
    <div
      style={{
        position: "relative",
        top: "-50px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "110px",
        textAlign: "center",
      }}>
      <div
        style={{
          position: "absolute",
          top: "2%",
          right: "2%",
          zIndex: "1000",
        }}>
        <IconButton
          sx={{
            backgroundColor: `${theme.palette.primary.main} !important`,
            borderRadius: "100% !important",
          }}
          onClick={onClick}>
          <CameraAlt sx={{ color: "white" }} />
        </IconButton>
      </div>
      {shopData.logo ? (
        <LazyLoadImage
          src={shopData.logo}
          alt='shop-logo'
          effect={"black-and-white"}
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            backgroundColor: "transparent",
            padding: ".2rem",
          }}
        />
      ) : (
        <LazyLoadImage
          src={NoImage}
          alt='image-placeholder'
          effect={"black-and-white"}
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            backgroundColor: "#F2F2F2",
            padding: ".2rem",
            objectFit: "contain",
          }}
        />
      )}
    </div>
  );
};

export default ShopLogoAvatar;
