import React, { useState, useEffect } from "react";
import { useParams } from "@reach/router";
import useLoggedUser from "../../custom-hooks/useLoggedUser";
import { navigate } from "gatsby-link";
import { getUserFromExternalToken } from "../../api/public/user";
import { getShop } from "../../api/public/shop";

const FlutterRoute = ({ component: Component, location, ...rest }) => {
  const params = useParams();
  const { cacheLoginData } = useLoggedUser();

  const [userDetails, setuserDetails] = useState(null);
  const [shopDetails, setshopDetails] = useState(null);

  useEffect(() => {
    console.log(params);
    if (params.token && params.userId && params.shopId) {
      //Get user details from backend
      getUserFromExternalToken(params.userId, params.token)
        .then((response) => {
          if (response?.status === 200) {
            response?.json().then((result) => {
              setuserDetails(result);
            });
          } else {
            navigate("/login");
          }
        })
        .catch((e) => navigate("/login"));

      //Get shop details from backend
      getShop(params.shopId)
        .then((response) => {
          if (response?.status === 200) {
            response?.json().then((result) => {
              setshopDetails(result);
            });
          } else {
            navigate("/login");
          }
        })
        .catch((e) => navigate("/login"));
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    //This object is structured like the sign in json response
    let signInObject = {
      token: params.token,
      user: userDetails,
      shop: shopDetails,
    };
    //Save to local storage
    if (userDetails && shopDetails) {
      console.log(signInObject);
      cacheLoginData(signInObject);
    }
  }, [userDetails, shopDetails]);

  return <Component {...rest} />;
};

export default FlutterRoute;
