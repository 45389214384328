import React, { useContext } from "react";
import { CameraAlt, Notifications } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Switch,
  Toolbar,
  Typography,
  Alert
} from "@mui/material";
import { navigate } from "gatsby-link";
import useLoggedUser from "../../../custom-hooks/useLoggedUser";
import SettingsItem from "./components/SettingsItem";
import useEditProfile from "./dialogs/useEditProfile";
import useEditProfilePicture from "./dialogs/useEditProfilePicture";
import useConfirmationDialog from "../../dialogs/useConfirmationDialog";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "animate.css";
import ProfilePhotoAvatar from "../../misc/ProfilePhotoAvatar";

import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { ThemeProvider } from "@emotion/react";
import { Box, styled } from "@mui/system";

import useSparkleAlert from "../../alert/useSparkleAlert";
import { testnotif } from "../../../api/public/auth";

const DarkModeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const SettingsPage = () => {
  const { user, removeUserData } = useLoggedUser();
  
  const { showSparkleAlert, SparkleAlert } = useSparkleAlert();

  const [showEditProfileDialog, closeEditProfileDialog, EditProfileDialog] =
    useEditProfile();

  const [showEditProfilePicture, closeEditProfilePicture, EditProfilePicture] =
    useEditProfilePicture();

  const [showConfirmationDialog, ConfirmationDialog] = useConfirmationDialog();

  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);

  return (
    <ThemeProvider theme={themeValues}>
      <CssBaseline />
      <div className='animate__animated animate__fadeIn'>
        <AppBar elevation={0}>
          <Toolbar>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              Settings
            </Typography>
            <ProfilePhotoAvatar
              src={user?.photo}
              alt={user?.name}
              onClick={() => {
                showEditProfilePicture(user);
              }}
            />
          </Toolbar>
        </AppBar>
        <Toolbar />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "300px",
            }}>
            <SparkleAlert />
            <SettingsItem
              icon={<CameraAlt />}
              title='Edit Profile Picture'
              onClick={() => {
                showEditProfilePicture(user);
              }}
            />
            <SettingsItem
              title='Edit Profile Details'
              onClick={() => {
                showEditProfileDialog(user);
              }}
            />
            <SettingsItem
              icon={<Notifications />}
              title='Notification'
              onClick={() => {
                
              testnotif(user)
              .then((response) => {
                console.log(response)
                if (response.status === 200) {
                  showSparkleAlert("A Test Notification will be sent to you shortly!", "success");
                } else {
                  response?.json()?.then((result) => {
                    showSparkleAlert(`${result.error}`, "error");
                  });
                }
              })
              .catch((e) => console.log(e));
              // showSparkleAlert("A Test Notification will be sent to you shortly!", "success");
              }}
            />
            
            <DarkModeSwitch
              onChange={toggleDarkTheme}
              checked={themeName === "light" ? false : "true"}
              sx={{
                marginBottom: "1em",
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <Button
                variant='contained'
                sx={{
                  width: "300px",
                }}
                onClick={() => {
                  showConfirmationDialog({
                    title: "Logout?",
                    message: "You are about to logout",
                  });
                }}>
                Logout
              </Button>
            </div>
          </div>
        </div>
        
        <EditProfileDialog />
        <EditProfilePicture />
        <ConfirmationDialog
          onConfirm={() => {
            let postMessageData = {
              type: "LOGOUT",
              payload: "",
            };


            if (window.ReactNativeWebView?.postMessage) {

              window.ReactNativeWebView.postMessage(
                JSON.stringify(postMessageData)
              );
            }

            removeUserData();

            navigate("/login");
          }}
        />
        
      </div>
    </ThemeProvider>
  );
};

export default SettingsPage;
