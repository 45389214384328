import React, { useState } from "react";
import capitalizeFirstLetter from "../../../helpers/capitalize";
import StorefrontIcon from "@mui/icons-material/Storefront";
import "../../../styles/css/fonts.css";
import useLoggedUser from "../../../custom-hooks/useLoggedUser";
import { updateShopStatus } from "../../../api/public/shop";
import useConfirmationDialog from "../../dialogs/useConfirmationDialog";
import {
	AppBar,
	CircularProgress,
	IconButton,
	Toolbar,
	Typography,
} from "@mui/material";

const TopBar = ({ title = "title", subtitle = "a short subtitle" }) => {
	const { userShop, cacheShop } = useLoggedUser();

	const [showConfirmationDialog, ConfirmationDialog] = useConfirmationDialog();

	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setIsLoading(true);
		updateShopStatus(userShop._id, !userShop.status)
			.then((response) => {
				if (response.status === 200) {
					response.json().then((result) => {
						setIsLoading(false);
						cacheShop(result);
					});
				} else {
					response.json().then((result) => {
						setIsLoading(false);
						console.log(result);
					});
				}
			})
			.catch((e) => console.log(e));
	};

	return (
		<div>
			<AppBar elevation={0}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						margin: "0.4em",
					}}
				>
					<div>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							{capitalizeFirstLetter(title)}
						</Typography>
						<Typography variant="p" fontSize="12px" sx={{ flexGrow: 1 }}>
							{subtitle}
						</Typography>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<IconButton>
							<StorefrontIcon style={{ color: "white" }} />
						</IconButton>
						<div>
							<div
								style={{
									fontSize: "12px",
								}}
							>
								{"Status"}
							</div>
							<div
								style={{
									fontSize: "14px",
									fontWeight: "bold",
									color: userShop.status ? "#00e400" : "red",
								}}
								onClick={() => {
									showConfirmationDialog({
										title: "Do you want to continue?",
										message: `You are about to ${
											userShop.status ? "close" : "open"
										} your shop`,
									});
								}}
							>
								{isLoading ? (
									<CircularProgress size="1em" />
								) : userShop?.status ? (
									"Open"
								) : (
									"Close"
								)}
							</div>
						</div>
					</div>
				</div>
			</AppBar>
			<ConfirmationDialog onConfirm={handleClick} />
			<Toolbar />
		</div>
	);
};

export default TopBar;
