import React, { useState, useRef, useEffect } from "react";
import { Close, HighlightOff } from "@mui/icons-material";
import {
  AppBar,
  Button,
  Chip,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Box,
  Slide,
  Stack,
  Toolbar,
  Typography,
  DialogContent,
} from "@mui/material";
import { getCategories } from "../../../../api/public/categories";
import ListItem from "../../../cards/ListItem";
import { Form, Formik } from "formik";
import * as yup from "yup";
import CustomTextField from "../../../textfield/CustomTextField";
import CustomMultiLineTextField from "../../../textfield/CustomMultiLineTextField";
import { addProduct } from "../../../../api/public/product";
import useLoggedUser from "../../../../custom-hooks/useLoggedUser";
import useSparkleSnackbar from "../../../../custom-hooks/useSparkleSnackbar";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import ProductOptions from "../../../ProductOptions";
import CustomCheckbox from "../../../checkbox";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  name: yup.string().required(),
  price: yup.number().positive("Price must be greater than zero").required(),
  quantity: yup
    .number()
    .positive("Stock must be greater than zero")
    .required("Number of stocks is required"),
  description: yup.string().max(300, "Description is too long").required(),
  ingredients: yup.string().max(300, "Ingredients is too long").required(),
});

const AddProductDialog = ({ open, handleClose = () => {} }) => {
  const inputFile = useRef(null);

  const productSizes = useRef(null);
  const productAddons = useRef(null);

  const { userShop } = useLoggedUser();

  const [forPets, toggleForPets] = useState(false);

  const [selectedImagesList, setselectedImagesList] = useState([]);

  const [selectedImageFiles, setselectedImageFiles] = useState([]);

  const [selectedCategories, setselectedCategories] = useState([]);

  const [categories, setcategories] = useState([]);

  const [showSnackbar, closeSnackBar, SparkleSnackBar] = useSparkleSnackbar();

  const fileSelectedHandler = async (e) => {
    if (e.target.files[0]) {
      setselectedImagesList((prevState) => [
        ...prevState,
        URL.createObjectURL(e.target.files[0]),
      ]);
      setselectedImageFiles((prevState) => [...prevState, e.target.files[0]]);
    }
  };

  function collectCategoryId() {
    let arr = [];
    for (let index = 0; index < selectedCategories.length; index++) {
      arr.push(selectedCategories[index]._id);
    }
    return arr;
  }

  function isCategorySelected(category) {
    let result = false;
    for (let index = 0; index < selectedCategories.length; index++) {
      if (category._id === selectedCategories[index]._id) {
        result = true;
      }
    }

    return result;
  }

  function addToSelectedCategoriesHandler(category) {
    if (isCategorySelected(category)) {
      let newList = selectedCategories.filter(
        (item) => item._id !== category._id
      );
      setselectedCategories(newList);
      return;
    }
    setselectedCategories((prevState) => [...prevState, category]);
  }

  function removeData() {
    setselectedImagesList([]);
    setselectedImageFiles([]);
    setselectedCategories([]);
  }

  useEffect(() => {
    getCategories().then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          //console.log(result);
          setcategories([...result]);
        });
      }
    });
  }, []);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting, resetForm }) => {
          console.log(data);

          if (collectCategoryId().length <= 0) {
            setSubmitting(false);
            showSnackbar("Please select a category", "error");
            return;
          }

          if (selectedImageFiles.length <= 0) {
            setSubmitting(false);
            showSnackbar("We need at least one picture", "error");
            return;
          }

          let productData = {
            ...data,
            shopId: userShop._id,
            images: selectedImageFiles,
            category: collectCategoryId()[0],
            discount: data.discount?.length > 0 ? data.discount : "0",
            sizes: productSizes.current.getProductSizes(),
            addons: productAddons.current.getProductSizes(),
            isForPets: forPets,
          };

          console.log(productData);

          addProduct(productData, userShop._id).then((response) => {
            if (response.status === 200) {
              response.json().then((result) => {
                setSubmitting(false);
                console.log(result);
                showSnackbar(
                  "New product uploaded, please give us moment to approve it",
                  "success"
                );
                resetForm({
                  values: {
                    name: "",
                    price: "",
                    quantity: "",
                    description: "",
                    ingredients: "",
                  },
                });
                removeData();
              });
            } else {
              response.json().then((result) => {
                setSubmitting(false);
                showSnackbar(result.error, "error");
                console.log(result);
              });
            }
          });
          // }
          setSubmitting(true);

          // let productData = {
          // 	...data,
          // 	shopId: userShop._id,
          // 	images: selectedImageFiles,
          // 	category: collectCategoryId()[0],
          // };

          // addProduct(productData, userShop._id).then((response) => {
          // 	if (response.status === 200) {
          // 		response.json().then((result) => {
          // 			setSubmitting(false);
          // 			console.log(result);
          // 			showSnackbar(
          // 				"New product uploaded, please give us moment to approve it",
          // 				"success"
          // 			);
          // 			resetForm({
          // 				values: {
          // 					name: "",
          // 					price: "",
          // 					quantity: "",
          // 					description: "",
          // 					ingredients: "",
          // 				},
          // 			});
          // 			removeData();
          // 		});
          // 	} else {
          // 		response.json().then((result) => {
          // 			setSubmitting(false);
          // 			showSnackbar(result.error, "error");
          // 			console.log(result);
          // 		});
          // 	}
          // });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <AppBar sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <Close />
                </IconButton>
                <Typography
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Add Product
                </Typography>
                <Button color="inherit" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "loading" : "save"}
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <CustomCheckbox
                value={forPets}
                onClick={() => {
                  toggleForPets(!forPets);
                }}
              />
              <Stack spacing={2}>
                <CustomTextField
                  name="name"
                  label="Product name"
                  variant="outlined"
                />
                <CustomTextField
                  name="price"
                  label="Price"
                  variant="outlined"
                />
                <CustomTextField
                  name="discount"
                  label="Product Discount"
                  variant="outlined"
                  required={false}
                />
                <CustomTextField
                  name="quantity"
                  label="No. in stock"
                  variant="outlined"
                />
                <CustomMultiLineTextField
                  name="description"
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={3}
                />

                <CustomMultiLineTextField
                  name="ingredients"
                  label="Ingredients"
                  variant="outlined"
                  multiline
                  rows={3}
                />
                <Divider />
                <Typography>
                  Uy may sizes yung product mo? Lagay mo na!
                </Typography>
                <ProductOptions
                  type="sizes"
                  label="Size"
                  value="Price"
                  ref={productSizes}
                />
                <Typography>
                  Pwede mag add-on sa product mo? Lagay mo din yan!
                </Typography>
                <ProductOptions
                  type="addon"
                  label="Add-on"
                  value="Price"
                  ref={productAddons}
                />
                {/* Select Category section */}
                <Paper
                  sx={{
                    p: 0.5,
                  }}
                >
                  <Typography>Select categories for this product</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      listStyle: "none",
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {categories.map((category) => {
                      return (
                        <ListItem>
                          <Chip
                            label={category.name}
                            variant={
                              isCategorySelected(category)
                                ? "filled"
                                : "outlined"
                            }
                            color={
                              isCategorySelected(category) ? "primary" : "info"
                            }
                            onClick={() => {
                              addToSelectedCategoriesHandler(category);
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </Box>
                </Paper>
                {/* Add Photo Section */}
                <div>
                  <Typography>Add pictures</Typography>
                  <Stack direction="row">
                    <Button
                      variant="contained"
                      onClick={() => {
                        inputFile.current.click();
                      }}
                    >
                      Upload a photo
                    </Button>
                    <input
                      type="file"
                      id="file"
                      ref={inputFile}
                      onChange={(e) => fileSelectedHandler(e)}
                      style={{ display: "none" }}
                    />
                  </Stack>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedImagesList.map((image, index) => {
                      return (
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <IconButton
                            sx={{
                              position: "absolute",
                              top: "1%",
                              right: "1%",
                              // color: "#0286FE",
                              color: "red",
                              zIndex: "999",
                            }}
                            onClick={() => {
                              console.log(image);
                              let newList = selectedImagesList.filter(
                                (item) => item !== image
                              );
                              setselectedImagesList([...newList]);

                              let newFileList = selectedImageFiles.filter(
                                (item) => item !== image
                              );
                              setselectedImageFiles([...newFileList]);
                            }}
                          >
                            <HighlightOff />
                          </IconButton>
                          <div
                            style={{
                              margin: "0.2em",
                            }}
                          >
                            <LazyLoadImage
                              src={image}
                              alt="product-image"
                              height="100px"
                              width="100px"
                              effect={"black-and-white"}
                              style={{
                                borderRadius: "0.5em",
                                objectFit: "cover",
                                margin: "0.2em",
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Stack>
            </DialogContent>
          </Form>
        )}
      </Formik>
      <SparkleSnackBar />
    </Dialog>
  );
};

export default AddProductDialog;
