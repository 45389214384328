import React from "react";
import HomePageContext from "./HomePageContext/HomePageContext";
import OrderPageContext from "./OrderPageContext/OrderPageContext";
import ShopPageContext from "./ShopPageContext/ShopPageContext";

const MainContext = ({ children }) => {
  return (
    <HomePageContext>
      <OrderPageContext>
        <ShopPageContext>{children}</ShopPageContext>
      </OrderPageContext>
    </HomePageContext>
  );
};

export default MainContext;
