import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const CustomCheckbox = ({value = false, onClick = () => {}, label = "for Pets"}) => {
	return <FormControlLabel control={
		<Checkbox name="checkedB" value={value} color="primary"  onChange={onClick}/>
	}
		label={label}
	/>
}


export default CustomCheckbox