import React from 'react'
import Iframe from 'react-iframe'
import FullScreenDialog from '../../dialogs/fullScreenDialog'

const ForwardTogetherAgreementDialog = ({open, onClose =() => {}}) => {
	return <FullScreenDialog open = {open} onClose = {onClose}>
		<Iframe width='100%' height='100%' url='https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Merchant%20Cooperation%20Agreement.pdf?alt=media&token=7cc9c702-c99a-4b63-903e-b72672ecb585' />
	</FullScreenDialog>
}


export default ForwardTogetherAgreementDialog